import { createSelector } from 'reselect';

import { searchActions } from '../../actions/actions-const';

const initialState = {
  hidden: false,
  isActive: false,
  recentItems: [],
  query: '',
  resultsAlgolia: null,
  isLoading: false,
};

// prevents the cached search results to show up before the search background
function setResult(result) {
  setTimeout(() => result);
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case searchActions.SEARCH_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case searchActions.HIDE_SEARCH:
      return {
        ...state,
        hidden: action.hidden,
      };
    case searchActions.SET_SEARCH_ACTIVE:
      setResult(state.results);
      return {
        ...state,
        isActive: action.active,
        resultsAlgolia: initialState.resultsAlgolia,
        // If search is no longer active set query to initial state
        query: action.active ? state.query : initialState.query,
      };
    case searchActions.SET_SEARCH_QUERY:
      return {
        ...state,
        isActive: true,
        query: action.query,
      };
    case searchActions.SET_SEARCH_RESULTS_ALGOLIA:
      return {
        ...state,
        resultsAlgolia: action.resultsAlgolia,
        isLoading: false,
      };
    case searchActions.SET_SEARCH_RECENT:
      return {
        ...state,
        recentItems: action.recentItems,
      };
    default:
      return state;
  }
}

/**
 * Given the current state, it returns whether or not search is in recent mode
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getIsRecent = (state) => state.search.isRecent;

/**
 * Given the current state, it returns whether or not search is active
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getSearchIsActive = (state) => state.search.isActive;

/**
 * Given the current state, it returns whether or not search is hidden
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getSearchIsHidden = (state) => state.search.hidden;
/**
 * Given the current state, it returns the search query
 *
 * @param {object} state
 * @returns {string}
 */
export const getSearchQuery = (state) => state.search.query;

/**
 * Given the current state, it returns whether or not index is loaded
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getIndexLoaded = (state) => state.search.isIndexLoaded;

/**
 * Given the current state, it returns if the search is loading
 *
 * @param {object} state
 * @returns {boolean}
 */
export const getSearchIsLoading = (state) => state.search.isLoading;

/**
 * Given the current state, it returns the recent search items
 *
 * @param {object} state
 * @returns {Array}
 */
export const getRecentSearchItems = (state) => state.search.recentItems;

/**
 * Given the current state, it returns what is user currently typing to search juke
 *
 * @param {object} state
 * @returns {object}
 */
export const getUserSearch = createSelector([getSearchIsActive, getSearchQuery], (isSearchActive, searchQuery) =>
  isSearchActive && searchQuery !== '' ? searchQuery : null
);
