const description = 'Luister naar je favoriete radiostations Radio 538, Radio 10 & Sky Radio.';

// this will generate all meta except icons/images, which can be edited in index.html
export const baseMeta = {
  title: 'JUKE',
  canonical: 'https://juke.nl/',
  meta: {
    'http-equiv': {
      'content-type': 'text/html; charset=utf-8',
      'X-UA-Compatible': 'IE=11',
    },
    name: {
      description,
      'custom-apple-itunes-app': 'app-id=1191822805',
      'custom-google-play-app': 'app-id=com.e247.radio538',
      viewport: 'width=device-width, initial-scale=1, shrink-to-fit=no',
      'theme-color': '#000000',
      keywords: 'muziek, radio, luisteren, muziekgids, radio luisteren, radio shows, playlists',
      robots: process.env.REACT_APP_ROBOTS,
      'twitter:site': '@juke_nl',
      'twitter:domain': 'Juke',
      'twitter:creator': '@juke_nl',
      'twitter:card': 'summary_large_image',
    },
    property: {
      'og:type': 'website',
      'og:title': 'Radio, podcasts, non-stop radio luisteren | Luister online | JUKE.nl',
      'og:description': description,
      'og:url': 'https://juke.nl/',
      'og:image': `https://juke.nl${process.env.PUBLIC_URL}/JUKE-Share-image.jpg`,
    },
  },
  auto: {
    ograph: true,
  },
};
