import { parse as parseQuerystring } from 'querystringify';
import { AppDispatch, RootState } from 'store';
import { isEmpty } from 'util/index';
import history from 'lib/history';
import { searchActions } from './actions-const';
import getSearchParams from 'util/getSearchParams';
import { IFetchResults } from 'components/hooks';

const NUMBER_OF_SAVES_IN_STORAGE = 20;

function updateLocation() {
  return (_dispatch: AppDispatch, getState: () => RootState): void => {
    const {
      search: { isActive, query },
    } = getState();

    const { search } = window.location;
    let searchParams = {};
    if (search) {
      searchParams = getSearchParams(search);
    }

    if (isActive) {
      searchParams = {
        search: encodeURIComponent(query),
      };
    } else {
      delete searchParams['search'];
    }

    let locationSearch = undefined;

    if (!isEmpty(searchParams)) {
      locationSearch = `?${Object.keys(searchParams)
        .map((key) => `${key}=${searchParams[key]}`)
        .join('&')}`;
    }

    if (locationSearch !== history.location.search && locationSearch !== undefined) {
      history.push({
        pathname: history.location.pathname,
        search: locationSearch,
      });
    }
  };
}

export function setActive(shouldBeActive: boolean) {
  return (dispatch: AppDispatch, getState: () => RootState): void => {
    const {
      search: { isActive },
    } = getState();
    if (isActive !== shouldBeActive) {
      dispatch({
        type: searchActions.SET_SEARCH_ACTIVE,
        active: shouldBeActive,
      });
      dispatch(updateLocation());
    }
  };
}

export function setSearchResults(results: Pick<IFetchResults, 'sections'>): (dispatch: AppDispatch) => void {
  return (dispatch: AppDispatch) => {
    dispatch({ type: searchActions.SET_SEARCH_RESULTS_ALGOLIA, resultsAlgolia: results });
  };
}

export function setSearchIsLoading(): (dispatch: AppDispatch) => void {
  return (dispatch: AppDispatch) => {
    dispatch({ type: searchActions.SEARCH_IS_LOADING });
  };
}

export function saveRecentSearch(targetType: string, uri: string) {
  return (dispatch: AppDispatch, getState: () => RootState): void => {
    const {
      search: { recentItems },
    } = getState();
    if (!recentItems.some((el: { uri: string }) => el.uri === uri)) {
      recentItems.unshift({ uri, targetType });
    }
    if (recentItems.length >= NUMBER_OF_SAVES_IN_STORAGE) {
      recentItems.splice(NUMBER_OF_SAVES_IN_STORAGE);
    }
    dispatch({ type: searchActions.SET_SEARCH_RECENT, recentItems });
  };
}

export function removeItemFromRecentSearch(uri: string) {
  return (dispatch: AppDispatch, getState: () => RootState): void => {
    const {
      search: { recentItems },
    } = getState();
    dispatch({
      type: searchActions.SET_SEARCH_RECENT,
      recentItems: recentItems.filter((el: { uri: string }) => el.uri !== uri),
    });
  };
}

export function clearRecentSearch() {
  return (dispatch: AppDispatch): void => {
    dispatch({ type: searchActions.SET_SEARCH_RECENT, recentItems: [] });
  };
}

export function setHidden(shouldBeHidden: boolean) {
  return (dispatch: AppDispatch, getState: () => RootState): void => {
    const {
      search: { hidden },
    } = getState();
    if (hidden !== shouldBeHidden) {
      dispatch({ type: searchActions.HIDE_SEARCH, hidden: shouldBeHidden });
      dispatch(updateLocation());
    }
  };
}

export function searchAlgolia(query: string): (dispatch: AppDispatch) => void {
  return (dispatch: AppDispatch) => {
    dispatch({ type: searchActions.SET_SEARCH_QUERY, query });
    dispatch(updateLocation());
  };
}

export function determineSearchState(): (dispatch: AppDispatch, getState: () => RootState) => void {
  return (dispatch: AppDispatch, getState: () => RootState): void => {
    const {
      search: { isActive, query: queryFromState },
    } = getState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { search: query } = parseQuerystring(history.location.search);
    if (query === undefined && isActive) {
      dispatch(setActive(false));
    } else if (typeof query === 'string' && queryFromState && isActive) {
      return dispatch(searchAlgolia(query));
    } else if (typeof query === 'string') {
      return dispatch(searchAlgolia(query));
    }
  };
}
