import React from 'react';
import PropTypes from 'prop-types';

import { injectStyles } from 'injectStyles';

import RadioError from 'components/svg/RadioError';

import { gutter } from 'cssVariables';

const UnknownError = ({ classes }) => {
  const timestamp = Math.round(new Date().getTime() / 1000);
  return (
    <div className={classes.container}>
      <RadioError />
      <h1>Oeps... er ging iets mis.</h1>
      <p>Hier zijn wat suggesties om je op weg te helpen:</p>
      <a className={classes.suggestedLink} href={`/?preventCache=${timestamp}`}>
        Home
      </a>
      {/* <a className={classes.suggestedLink} href={`/radioshows?preventCache=${timestamp}`}>
        radioshows
      </a>
      <a className={classes.suggestedLink} href={`/muziekgenres?preventCache=${timestamp}`}>
        muziekgenres
      </a> */}
    </div>
  );
};

UnknownError.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '200px',
  },
  suggestedLink: {
    textTransform: 'uppercase',
    display: 'inline-block',
    marginTop: gutter,
    '&:nth-of-type(2)': {
      marginLeft: gutter * 3,
      marginRight: gutter * 3,
    },
  },
};

export default injectStyles(styles)(UnknownError);
