import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Markdown from 'marked-react';
import { injectStyles } from 'injectStyles';
import graphql from 'api/graphql/index';
import * as cssVariables from 'cssVariables';
import PlayControl from 'components/PlayControl';
import { DisplayLayout } from 'components/Collection/Collection.const';
// import { getImageWithResamplingQuery } from 'util/resampling';
import { isTouch } from 'util/device';
import layoutTypes from './Card.const';

class Card extends PureComponent {
  static defaultProps = {
    buttonText: null,
    display: null,
    image: null,
    isLoading: false,
    isPlaying: false,
    label: null,
    layout: 'Default',
    onLoad: undefined,
    onStop: undefined,
    pageLink: null,
    playStationSlug: null,
    showTitle: false,
    text: null,
    title: null,
    url: null,
  };

  static propTypes = {
    buttonText: PropTypes.string,
    display: PropTypes.string,
    classes: PropTypes.object.isRequired,
    image: PropTypes.shape({
      altText: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    isPlaying: PropTypes.bool,
    label: PropTypes.string,
    layout: PropTypes.string,
    onLoad: PropTypes.func,
    onStop: PropTypes.func,
    pageLink: PropTypes.shape({
      slug: PropTypes.string,
    }),
    playStationSlug: PropTypes.string,
    showTitle: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
  };

  handlePlayToggle = async () => {
    const { playStationSlug, isPlaying, onLoad, onStop } = this.props;
    if (isPlaying) {
      return onStop();
    }
    const station = await graphql.fetchStation({ slug: playStationSlug });
    return onLoad(station);
  };

  render() {
    const {
      // buttonText,
      classes,
      display: collectionDisplay,
      image,
      isLoading,
      isPlaying,
      label,
      layout,
      // pageLink,
      playStationSlug,
      // showTitle,
      text,
      // title,
      url,
    } = this.props;

    // const buttonClasses = classNames(classes.button, {
    //   [classes.buttonGreen]: layout === layoutTypes.default,
    //   [classes.buttonWhite]: layout !== layoutTypes.default,
    // });

    if (!url) return null;

    return (
      <a
        // onClick={playStationSlug && layout === layoutTypes.tile ? this.handlePlayToggle : undefined}
        // role="presentation"
        href={url}
        className={classNames(classes.wrapper, {
          [classes.default]: layout === layoutTypes.default,
          [classes.square]: layout === layoutTypes.square || layout === layoutTypes.tile,
          [classes.tile]: layout === layoutTypes.tile,
          [classes.inTileCollection]: collectionDisplay === DisplayLayout.TILES,
        })}
      >
        {image && layout === layoutTypes.default && (
          <img data-key="tile-image" src={image.url} className={classes.imageDefault} alt={image.title} />
        )}
        <div
          className={classNames({
            [classes.content]: layout !== layoutTypes.default,
            [classes.contentWithBackgroundImage]:
              image && (layout === layoutTypes.square || layout === layoutTypes.tile),
            [classes.gradientOverlay]: layout === layoutTypes.tile,
            [classes.hoverAnimation]: !isTouch,
          })}
        >
          {label && layout === layoutTypes.square && <h2 className={classes.secondaryTitle}>{label}</h2>}
          {layout === layoutTypes.tile && (
            <Fragment>
              {playStationSlug && (
                <div className={classes.playControls}>
                  <div
                    className={classNames(classes.playButton, {
                      [classes.noBackground]: isLoading,
                    })}
                  >
                    <PlayControl
                      playToggle={this.handlePlayToggle}
                      isLoading={isLoading}
                      isPlaying={isPlaying}
                      size={55}
                      isCTA
                    />
                  </div>
                </div>
              )}
              {label && <span className={classes.label}>{label}</span>}
            </Fragment>
          )}

          {/* {(showTitle || layout !== layoutTypes.default) && (
            <h2
              className={classNames({
                [classes.title]: layout !== layoutTypes.default,
              })}
            >
              {title}
            </h2>
          )} */}
          {text && (
            <div
              className={classNames(classes.text, {
                [classes.textDefault]: layout === layoutTypes.default,
              })}
            >
              <Markdown>{text}</Markdown>
            </div>
          )}

          {/* <div className={classes.buttonWrapper}>
            {playStationSlug && layout !== layoutTypes.tile && (
              <button className={buttonClasses} type="button" onClick={this.handlePlayToggle}>
                {isPlaying ? 'Speelt af' : buttonText || 'Nu luisteren'}
              </button>
            )}
            {!playStationSlug && (url || pageLink) && (
              <NavigationLink
                className={buttonClasses}
                source="CallToActionBlock"
                pageLink={pageLink}
                pageUrl={url}
                title={buttonText}
              >
                {buttonText || 'Bekijk nu'}
              </NavigationLink>
            )}
          </div> */}
        </div>
      </a>
    );
  }
}

const styles = (theme) => ({
  wrapper: {
    // Fixes rendering issue when swiping
    transform: 'scale(1) perspective(1px)',
    // marginTop: 10,
    // marginBottom: 10,

    display: 'block',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  inTileCollection: {
    marginTop: 5,
    marginBottom: 5,
  },
  default: {
    '& $buttonWrapper': {
      textAlign: 'center',
    },
  },
  imageDefault: {
    width: '100%',
    display: 'block',
  },
  content: {
    cursor: 'pointer',
    padding: '36px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '36px 10px',
    },
  },
  square: {
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
      cursor: 'pointer',
    },
    '& $content': {
      borderRadius: 10,
      width: '100%',
      height: '100%',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        padding: 30,
      },
    },
    '& $content:not($contentWithBackgroundImage)': {
      color: cssVariables.white,
      background: `linear-gradient(148.06deg, #37B376 0%, ${cssVariables.green500} 62.14%, #FACF18 100%)`,
    },
    '& $title': {
      fontSize: cssVariables.fontSizes.xxlarge,
      fontWeight: cssVariables.fontWeights.bold,
      textTransform: 'uppercase',
      lineHeight: '40px',
    },
    '& $buttonWrapper': {
      marginTop: 0,
    },
  },
  tile: {
    // Fixes rendering issue when hovering and the parent tile is transformed
    transform: 'scale(1) perspective(1px)',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    borderRadius: 8,

    '&:after': {
      paddingBottom: '91.4%',
    },
    '& $buttonWrapper': {
      textAlign: 'center',
    },
    '& $title': {
      fontSize: cssVariables.fontSizes.xlarge,
      hyphens: 'none',
    },
    '& $text': {
      fontSize: cssVariables.fontSizes.small,
      maxHeight: 22,
      transition: 'max-height 300ms ease-out',
      lineHeight: '22px',
      overflow: 'hidden',
      '& p': {
        margin: 0,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    '& $content:hover $text': {
      maxHeight: 66,
      overflow: 'visible',
    },
    '& $content:hover $label': {
      backgroundColor: cssVariables.white,
      color: cssVariables.green500,
    },
    '& $content:hover $playButton': {
      backgroundColor: cssVariables.white,
      '& svg': {
        color: cssVariables.green500,
      },
      '& polygon, & rect': {
        fill: cssVariables.green500,
      },
    },
  },
  playControls: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  playButton: {
    position: 'relative',
    backgroundColor: cssVariables.transparentLightmode040,
    transition: 'all 300ms ease',
    borderRadius: 55,
    marginBottom: 15,
  },
  hoverAnimation: {},
  gradientOverlay: {
    overflow: 'hidden',
    background: cssVariables.transparentLightmode030,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -20,
      left: -20,
      width: 'calc(100% + 40px)',
      height: 'calc(100% + 40px)',
      backgroundImage: `linear-gradient(122deg, #3c9cdc, #37b376 38%, ${cssVariables.green500} 67%, #facf18)`,
      backgroundPosition: 'center',
      backgroundSize: '100%',
      opacity: 0.8,
      transition: 'transform 150ms ease-in-out',
      transform: 'perspective(1px) translateY(60%) skewY(40deg)',
      zIndex: 0,
    },
    '& $title, $text, $playControls': {
      position: 'relative',
      zIndex: 2,
      transform: 'scale(1) perspective(1px)',
    },
    '&:focus': {
      outline: 0,
      outlineOffset: 0,
      '&$hoverAnimation:before': {
        transform: 'none',
      },
    },

    '&$hoverAnimation:hover': {
      '&::before': {
        transform: 'none',
      },
    },
  },
  contentWithBackgroundImage: {
    color: cssVariables.white,
    backgroundImage: ({ image }) => image && `url(${image.url})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    margin: -1,
    // Add black Gradient Overlay
    zIndex: 0,
    overflow: 'hidden',
    '& $title, $text, $playControls': {
      position: 'relative',
      zIndex: 2,
    },
    '&:after': {
      // content: '""',
      background: 'linear-gradient(180deg, rgba(40,43,46,0) 0%, #282B2E 100%)',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      opacity: 0.3,
    },
  },
  label: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: cssVariables.green500,
    transition: 'all 300ms ease',
    textTransform: 'uppercase',
    fontSize: cssVariables.fontSizes.xxsmall,
    fontWeight: cssVariables.fontWeights.bold,
    lineHeight: '20px',
    padding: '0 10px',
    borderRadius: 2,
  },
  triangle: {
    ...cssVariables.fillAspectRatio,
    zIndex: 1,
    opacity: 0.9,
  },
  secondaryTitle: {
    fontSize: cssVariables.fontSizes.medium,
    fontWeight: cssVariables.fontWeights.bold,
  },
  title: {
    margin: 0,
    fontSize: cssVariables.fontSizes.xlarge,
    [theme.breakpoints.down('xs')]: {
      fontSize: cssVariables.fontSizes.xlarge,
      lineHeight: '34px',
    },
  },
  text: {
    fontSize: cssVariables.fontSizes.medium,
    lineHeight: 1.3333,
    marginBottom: 0,
  },
  textDefault: {
    margin: `${cssVariables.gutter}px 0`,
    fontSize: cssVariables.fontSizes.small,
  },
  buttonWrapper: {
    marginTop: '40px',
  },
  button: {
    fontSize: cssVariables.fontSizes.medium,
    lineHeight: '20px',
    padding: '13px 15px',
    background: cssVariables.blue500,
    color: cssVariables.white,
    whiteSpace: 'nowrap',
    display: 'inline-block',
    textAlign: 'center',
    border: 'none',
    '&:hover': {
      background: cssVariables.grey600,
    },
    '&:focus': {
      background: cssVariables.blue500,
    },
  },
  noBackground: {
    background: 'none !important',
  },
  buttonGreen: {
    color: cssVariables.white,
    background: cssVariables.green500,
    borderRadius: 4,
    '&:hover, &:focus': {
      background: cssVariables.green600,
    },
  },
  buttonWhite: {
    color: cssVariables.grey600,
    background: cssVariables.white,
    borderRadius: 4,
    fontSize: 14,
    padding: '9px 33px',
    fontWeight: cssVariables.fontWeights.bold,
    textDecoration: 'none',
    '&:hover': {
      transform: 'translateY(-1px)',
      boxShadow: `0 1px 3px 0 ${cssVariables.transparentLightmode005}, 0 5px 10px 0 ${cssVariables.transparentLightmode010}`,
    },
    '&:hover, &:focus': {
      background: cssVariables.white,
    },
  },
});

export default injectStyles(styles)(Card);
