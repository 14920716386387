import * as analytics from 'analytics';
import invariant from 'invariant';
import VideoPlayer from '../VideoPlayer';
import logger from 'util/logger';

import { VIDEO_PLAYER_ID, JW_PLAYER_SCRIPT_SRC } from '../Players.const';
import { getStore } from 'store';
import { getVolumeLevel } from 'store/reducers/player';

class VideoJWPlayer extends VideoPlayer {
  constructor() {
    super();

    // A boolean needed to throw analytics events when playback is
    //  resumed with external play controls
    this.externalResume = false;

    // We do a check here to make sure the SDK isn't loaded more than once
    if (!window.jwplayer) {
      // Initialise the SDK and player
      // this.loadJwPlayerSDK();
    }
  }

  /**
   * Adds a <script> tag to the document HEAD and includes the JW Player minified sdk
   */
  loadJwPlayerSDK = () => {
    const script = document.createElement('SCRIPT');

    script.src = JW_PLAYER_SCRIPT_SRC;
    script.async = true;

    document.getElementsByTagName('HEAD')[0].appendChild(script);
  };

  /**
   *  Given a station object, returns the video source
   * @param {object} station
   * @returns {string}
   */

  getSources = (station) => {
    if (!station.videoUrl) {
      throw new Error(`Unknown station type ${station.type}.`);
    } else {
      return station.videoUrl;
    }
  };

  /**
   * Sets the play callback to be used by the player
   *
   * @param {function} callback
   */
  setPlayCallback(callback) {
    this.playCallback = callback;
  }

  /**
   * Sets the stop callback to be used by the player
   *
   * @param {function} callback
   */
  setStopCallback(callback) {
    this.stopCallback = callback;
  }

  /**
   * Sets the volume callback to be used by the player
   *
   * @param {function} callback
   */
  setVolumeCallback(callback) {
    this.volumeCallback = callback;
  }

  /**
   * Sets the mute callback to be used by the player
   *
   * @param {function} callback
   */
  setMuteCallback(callback) {
    this.muteCallback = callback;
  }

  /**
   * Sets the error callback to be used by the player
   *
   * @param {function} callback
   */
  setErrorCallback(callback) {
    this.errorCallback = callback;
  }

  /**
   * Given a videoStreamSource, initialises videoPlayer
   * @param {string} videoStreamSource
   */
  initialisePlayer(videoStreamSource, isMuted) {
    // Setup the player
    const store = getStore();
    const state = store.getState();
    const volumeLevel = (getVolumeLevel(state) || 0) * 100;
    this.player = window.jwplayer(VIDEO_PLAYER_ID)?.setup?.({
      file: videoStreamSource,
      controls: true,
      mute: isMuted,
      volume: volumeLevel,
      autostart: true,
      aspectratio: '1:1',
      cast: false,
    });

    // Binding the control events to dispatches,
    // to synchronize the controls of the JWPlayer with the controls of the player
    this.player.on('mute', (e) => {
      this.muteCallback(e.mute);
    });
    this.player.on('volume', (e) => {
      this.volumeCallback(e.volume / 100);
    });

    this.player.on('play', (e) => {
      this.playCallback();
      // Stop any heartbeat that is active
      analytics.stopHeartBeat();
      // Start heartbeat
      analytics.startHeartBeat();

      // If the playReason was 'interaction', we assume the play button
      // in the player was clicked and playback resumes
      // We also check if the playback was resumed externally
      if (e.playReason === 'interaction' || this.externalResume) {
        // Call the analytics stuff, because resuming from the JWPlayer
        // doesn't trigger resume() function in this object
        this.healthyPlayDetector.resume();
        analytics.trackResumeLiveVideo(this.station, this.player);
        this.externalResume = false;
      }
    });

    this.player.on('stop', () => {
      this.stopCallback();
      analytics.trackStopLiveVideo(this.station, this.player);
      // Stop any heartbeat that is active
      analytics.stopHeartBeat();
    });

    this.player.on('pause', () => {
      this.stopCallback();
      analytics.trackStopLiveVideo(this.station, this.player);
      // Stop any heartbeat that is active
      analytics.stopHeartBeat();
    });

    this.player.on('error', (error) => {
      logger.error(error);
      this.errorCallback(this.station);
    });
    this.player.on('setupError', (error) => {
      logger.error(error);
      this.errorCallback(this.station);
    });
  }

  /**
   * Setting the playback volume based on the given volumeLevel
   *
   * @param {number} volumeLevel
   */
  setVolumeLevel(volumeLevel) {
    if (this.player?.volume && this.player.volume !== volumeLevel * 100) {
      this.player.setVolume(volumeLevel * 100);
    }
  }

  /**
   * Mutes/Unmutes playback based on given muted
   *
   * @param {boolean} muted
   */
  setMuted(muted) {
    super.setMuted(muted);
    if (muted) {
      this.setVolumeLevel(0);
    }
    if (this.player && this.player.getMute?.() === muted) {
      this.player.setMute(muted);
    }
  }

  /**
   *  Loads and initialises the stream source of the current station
   */
  async load() {
    if (!window.jwplayer) {
      logger.info('load » SDK not ready yet');
      setTimeout(this.load.bind(this), 200);
      return;
    }

    const videoStreamSource = this.getSources(this.station);
    this.initialisePlayer(videoStreamSource, false);

    try {
      const imageUrl = this.station && this.station.image ? this.station.image.url : '';
      this.setMediaSessionData(this.station.name, imageUrl);
      this.player.play();
      analytics.trackPlayLiveVideo(this.station, this.player);
    } catch (error) {
      this.errorCallback(this.station);
      logger.debug('Swallowed an error while calling load()', error.code, error);
    }
  }

  /**
   * Resumes video playback
   */
  async resume() {
    try {
      invariant(this.playSessionId && this.station, 'PlaySessionId and Station should both be set.');
      await this.player.play();
      this.externalResume = true;
    } catch (error) {
      this.errorCallback(this.station);
      logger.debug('Swallowed an error while calling resume()', error.code, error);
    }
  }

  /**
   * Plays audio from a given station
   * @param {string} playSessionId
   * @param {object} station
   */
  async play(playSessionId, station) {
    // Reset this boolean
    this.externalResume = false;

    if (this.playSessionId === playSessionId) {
      // Resume playback if it's the same sesseion
      await this.resume();
    } else {
      // Set and load content on new session
      this.playSessionId = playSessionId;
      this.station = station;

      analytics.trackStartLiveVideo(this.station, this.player);

      await this.load();
      this.healthyPlayDetector.startNew();
    }
  }

  /**
   * Stops the video playback
   */
  stop(trackAnalytics = true) {
    super.stop();

    // Reset this boolean
    this.externalResume = false;

    if (this.player) {
      this.player.stop();
    }

    if (trackAnalytics) {
      // Stop any heartbeat that is active
      analytics.stopHeartBeat();
    }

    if (this.station && trackAnalytics) {
      analytics.trackStopLiveVideo(this.station);
    }
  }
}

const videoJWPlayer = new VideoJWPlayer();
export default videoJWPlayer;
