import { v4 as uuid } from 'uuid';
import invariant from 'invariant';
import { showNotification } from 'actions/notifications-actions';
import graphql from 'api/graphql';
import { isPopoutPlayer } from 'globalConst/const';
import { playbackModes } from 'store/reducers/player';
import { NotificationTypes, NotificationTexts } from '../components/Notifications/Notification';
import AudioPlayerStation from 'players/AudioPlayerStation';
import AudioPlayerOnDemand from 'players/AudioPlayerOnDemand';
import VideoJWPlayer from 'players/VideoJWPlayer';
import ChromeSender from 'players/ChromeSender';
import { setMuted, setVolumeLevel } from './playerVolume-actions';
import { trackHealthyPlayStation, trackHealthyPlayOnDemand } from './healthyPlay-actions';
import { inlineMediaStop } from './inline-media-actions';
import { playerActions, playerVolumeActions } from './actions-const';
import { castingStop, castingStart, castingPlaybackError } from './casting-actions';
import * as huaweiPlayerEvents from 'players/HuaweiPlayerEvents';

import { setStationInPopout, setClipInPopout, setPlaybackDurationInPopout } from './popout-actions';
import { isMobile, logger, noop } from 'util/index';
import { reportFailedAPIRequest } from 'metrics';
import { PodcastEpisode, Station } from 'api/models';
import { AppDispatch, RootState } from 'store';
import { Action } from 'redux';
import { setIsJWPlayerActive } from 'actions/jwPlayer-actions';
import { JW_PLAYER_LOAD_TIMEOUT, VIDEO_PLAYER_ID } from 'players/Players.const';

export const PLAYBACK_TYPE = {
  LIVE_AUDIO: 'liveAudio',
  ONDEMAND: 'ondemand',
  VIDEO: 'video',
};

export function playerStop(): Action<string> {
  return {
    type: playerActions.PLAYER_STOPPED,
  };
}

export function playerPlaying(): Action<string> {
  return {
    type: playerActions.PLAYER_PLAYING,
  };
}

export function setPlaybackToLiveAudio(): Action<string> {
  return {
    type: playerActions.PLAYER_SET_PLAYBACK_TO_LIVE_AUDIO,
  };
}

export function setPlaybackToLiveVideo(): Action<string> {
  return {
    type: playerActions.PLAYER_SET_PLAYBACK_TO_LIVE_VIDEO,
  };
}

export function setPlaybackToOnDemandClip(): Action<string> {
  return {
    type: playerActions.PLAYER_SET_PLAYBACK_TO_ON_DEMAND_CLIP,
  };
}

export function setUpcomingTracks(tracks: PodcastEpisode[]): Action<string> & { tracks: PodcastEpisode[] } {
  return {
    type: playerActions.PLAYER_SET_UPCOMING_TRACKS,
    tracks,
  };
}

export function emptyOnDemandPlayback(): Action<string> {
  return {
    type: playerActions.PLAYER_EMPTY_ON_DEMAND_PLAYBACK,
  };
}

export function setFullPlayerState(visible: boolean): Action<string> & { visible: boolean } {
  return {
    type: playerActions.PLAYER_SET_FULL_PLAYER,
    visible,
  };
}

export function forceMiniPlayerState(visible: boolean): Action<string> & { visible: boolean } {
  return {
    type: playerActions.PLAYER_FORCE_MINI_PLAYER,
    visible,
  };
}

export function hideMiniPlayer(payload: boolean): Action<string> & { payload: boolean } {
  return {
    type: playerActions.PLAYER_HIDE_MINI_PLAYER,
    payload,
  };
}

export function hidePlayer() {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({
      type: playerActions.PLAYER_HIDE_PLAYER,
    });
  };
}

export function castingAvailable(): Action<string> {
  return {
    type: playerActions.CASTING_AVAILABLE,
  };
}

export function castingUnavailable(): Action<string> {
  return {
    type: playerActions.CASTING_UNAVAILABLE,
  };
}

export function playerReconnecting(): Action<string> {
  return {
    type: playerActions.PLAYER_LOADING,
  };
}

export function playerReconnect(event: unknown) {
  return async (dispatch: AppDispatch): Promise<void> => {
    // dispatch({ type: playerVolumeActions.SET_VOLUME_LEVEL, volumeLevel: event.volume });
    dispatch({ type: playerActions.PLAYER_PLAYING });
  };
}

export function trackOnDemandPlaybackTime(onDemandPlaybackTime: number) {
  return async (dispatch: AppDispatch): Promise<void> => {
    if (isPopoutPlayer) {
      dispatch(setPlaybackDurationInPopout(onDemandPlaybackTime));
    }
    dispatch({
      type: playerActions.PLAYER_TRACK_ON_DEMAND_PLAYBACK_TIME,
      onDemandPlaybackTime,
    });
  };
}

export function setOnDemandPlaybackDuration(onDemandPlaybackDuration: number) {
  return async (dispatch: AppDispatch): Promise<void> => {
    if (isPopoutPlayer) {
      dispatch(setPlaybackDurationInPopout(onDemandPlaybackDuration));
    }
    dispatch({
      type: playerActions.PLAYER_SET_ON_DEMAND_PLAYBACK_DURATION,
      onDemandPlaybackDuration,
    });
  };
}

export function playbackError(
  type: string,
  error: { name: string; message: string },
  mediaItem: Station | PodcastEpisode,
  playSessionId: string
): (dispatch: AppDispatch) => void {
  const splunk_error_reporting = true;
  return (dispatch: AppDispatch): void => {
    const { name } = error || {};
    if (name === 'AbortError') {
      return;
    }
    if (name === 'PLAY_NOT_ALLOWED' || name === 'NotAllowedError') {
      dispatch(playerStop());
      return;
    }
    if (splunk_error_reporting) {
      reportFailedAPIRequest(`playback error/${error.message}`, mediaItem?.name, (mediaItem as Station)?.uuid);
    }

    const { title } = mediaItem || {};
    let message = null;
    switch (type) {
      case PLAYBACK_TYPE.ONDEMAND:
        message = NotificationTexts.PLAYBACK_CLIP_ERROR;
        logger.error(`${playSessionId} AudioPlayerStation.playOnDemandClip errored: ${error.message}`);
        break;
      case PLAYBACK_TYPE.VIDEO:
        message = `${title} ${NotificationTexts.PLAYBACK_LIVE_VIDEO_ERROR}`;
        logger.error(`${playSessionId} playLiveVideo.play errored: ${error.message}`);
        break;
      case PLAYBACK_TYPE.LIVE_AUDIO:
        message = `${title} ${NotificationTexts.PLAYBACK_STATION_ERROR}`;
        logger.error(`${playSessionId} playLiveAudio.play errored: ${error.message}`);
        break;
      default:
        break;
    }

    if (name === 'MixedContent') {
      message = `${
        title || 'Deze radiozender of podcast'
      } is tijdelijk niet beschikbaar in Google Chrome. Probeer een andere browser.`;
    }

    dispatch({
      type: playerActions.PLAYER_ERRORED,
      playSessionId,
      error: { name: error?.name, message: error?.message },
    });
    dispatch(
      showNotification({
        type: NotificationTypes.ERROR,
        message,
      })
    );
  };
}

export function skipOnDemandClipForward(skipTime: number): Action<string> & { skipTime: number } {
  AudioPlayerOnDemand.skipForward(skipTime);
  ChromeSender.skipForward(skipTime);
  huaweiPlayerEvents.message('skipForward', skipTime);
  return {
    type: playerActions.PLAYER_SKIP_ON_DEMAND_CLIP_FORWARD,
    skipTime,
  };
}

export function skipOnDemandClipBackward(skipTime: number): Action<string> & { skipTime: number } {
  AudioPlayerOnDemand.skipBackward(skipTime);
  ChromeSender.skipBackward(skipTime);
  huaweiPlayerEvents.message('skipBackward', skipTime);
  return {
    type: playerActions.PLAYER_SKIP_ON_DEMAND_CLIP_BACKWARD,
    skipTime,
  };
}

export const setTrackOnDemandPlaybackTime =
  (onDemandPlaybackTime: number) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    const {
      player: { playSessionId },
    } = getState();

    invariant(playSessionId, 'playSessionId should be set before calling setTrackOnDemandPlaybackTime()');

    dispatch({
      type: playerActions.PLAYER_SET_ON_DEMAND_PLAYBACK_TIME,
      onDemandPlaybackTime,
    });

    try {
      AudioPlayerOnDemand.skipTo(onDemandPlaybackTime);
      ChromeSender.skipTo(onDemandPlaybackTime);
    } catch (error) {
      logger.error(playSessionId, 'AudioPlayerOnDemand.play errored', error);
      dispatch({ type: playerActions.PLAYER_ERRORED, playSessionId, error });
    }
  };

export function attemptAutoPlayNext() {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const { player } = getState();
    if (!player.upcomingTracks || !player.upcomingTracks.length) return;
    const [nextTrack, ...tracks] = player.upcomingTracks;
    // eslint-disable-next-line no-use-before-define
    dispatch(loadOnDemandClip(nextTrack));
    dispatch(setUpcomingTracks(tracks));
  };
}

export function initPlayer() {
  return async (dispatch: AppDispatch): Promise<void> => {
    //ONLY ON HUAWEI DEVICES: Listen to the native audio player
    huaweiPlayerEvents.listener();

    AudioPlayerStation.trackHealthyPlay((playSessionId) => {
      dispatch(trackHealthyPlayStation(playSessionId));
    });

    AudioPlayerOnDemand.trackHealthyPlay((playSessionId) => {
      dispatch(trackHealthyPlayOnDemand(playSessionId));
    });

    VideoJWPlayer.trackHealthyPlay();

    AudioPlayerStation.endedPlay(() => {
      dispatch(playerStop());
    });

    AudioPlayerOnDemand.endedPlay(() => {
      dispatch(playerStop());
      dispatch(attemptAutoPlayNext());
    });

    AudioPlayerOnDemand.timeUpdate((playbackTime) => {
      dispatch(trackOnDemandPlaybackTime(playbackTime));
    });

    AudioPlayerOnDemand.emptied(() => {
      dispatch(emptyOnDemandPlayback());
    });

    AudioPlayerOnDemand.durationChanged((onDemandDuration) => {
      dispatch(setOnDemandPlaybackDuration(onDemandDuration));
    });

    ChromeSender.setCastingAvailableCallback(() => dispatch(castingAvailable()));
    ChromeSender.setCastingUnavailableCallback(() => dispatch(castingUnavailable()));
    ChromeSender.setCastingStopCallback(() => dispatch(castingStop()));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ChromeSender.setCastingStartCallback((device: any) => dispatch(castingStart(device)));
    ChromeSender.setPlayerPlayingCallback(() => dispatch(playerPlaying()));
    ChromeSender.setSetMutedCallback((isMuted: boolean) => dispatch(setMuted(isMuted)));
    ChromeSender.setSetVolumeLevelCallback((volume: number) => dispatch(setVolumeLevel(volume)));
    ChromeSender.setEndedPlayCallback(() => dispatch(playerStop()));
    ChromeSender.setTimeUpdateCallback((playbackTime: number) => dispatch(trackOnDemandPlaybackTime(playbackTime)));
    ChromeSender.setDurationChangedCallback((onDemandDuration: number) =>
      dispatch(setOnDemandPlaybackDuration(onDemandDuration))
    );
    ChromeSender.setErrorCallback((message: string) => dispatch(castingPlaybackError(message)));

    AudioPlayerOnDemand.setStopCallback(() => dispatch(playerStop()));
    AudioPlayerOnDemand.setPlayCallback(() => dispatch(playerPlaying()));
    AudioPlayerOnDemand.setErrorCallback((error, ondemandItem, playSessionId) =>
      dispatch(playbackError(PLAYBACK_TYPE.ONDEMAND, error, ondemandItem, playSessionId))
    );
    AudioPlayerOnDemand.setProgressSentCallback(() =>
      dispatch({
        type: playerActions.PROGRESS_UPDATE,
      })
    );
    AudioPlayerOnDemand.setReconnectingCallback(() => dispatch(playerReconnecting()));
    AudioPlayerOnDemand.setReconnectCallback((event: unknown) => dispatch(playerReconnect(event)));
    AudioPlayerOnDemand.setDisconnectedCallback(() => dispatch(playerStop()));

    AudioPlayerStation.setStopCallback(() => dispatch(playerStop()));
    AudioPlayerStation.setPlayCallback(() => dispatch(playerPlaying()));

    AudioPlayerStation.setErrorCallback((error, station, playSessionId) =>
      dispatch(playbackError(PLAYBACK_TYPE.LIVE_AUDIO, error, station, playSessionId))
    );
    AudioPlayerStation.setReconnectingCallback(() => dispatch(playerReconnecting()));
    AudioPlayerStation.setReconnectCallback((event: unknown) => dispatch(playerReconnect(event)));
    AudioPlayerStation.setDisconnectedCallback(() => dispatch(playerStop()));

    VideoJWPlayer.setErrorCallback((station: Station) =>
      dispatch(playbackError(PLAYBACK_TYPE.VIDEO, null, station, ''))
    );
    VideoJWPlayer.setVolumeCallback((volume: number) => dispatch(setVolumeLevel(volume)));
    VideoJWPlayer.setMuteCallback((mute: boolean) => dispatch(setMuted(mute)));
    VideoJWPlayer.setPlayCallback(() => {
      if (AudioPlayerOnDemand.isPlaying) {
        AudioPlayerOnDemand.stop();
      }
      if (AudioPlayerStation.isPlaying) {
        AudioPlayerStation.stop();
      }
      dispatch(playerPlaying());
    });
    VideoJWPlayer.setStopCallback(() => dispatch(playerStop()));
    dispatch({
      type: playerActions.PLAYER_INITIALIZED,
    });
  };
}

export function playOnDemandClip() {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const {
      player: { playSessionId, playbackMode, currentOnDemandClip, isCasting, onDemandPlaybackTime, onDemandDuration },
      popout: { isPopoutPlayerActive },
    } = getState();

    invariant(currentOnDemandClip, 'currentOnDemandClip should be set before calling playOnDemandClip()');
    invariant(playSessionId, 'playSessionId should be set before calling playOnDemandClip()');
    dispatch(setIsJWPlayerActive(false));

    // if popout player is active, only start playing in the popout layer
    if (isPopoutPlayerActive && !isPopoutPlayer) {
      dispatch(setClipInPopout(currentOnDemandClip, onDemandPlaybackTime));
      return;
    }

    try {
      dispatch({ type: playerActions.PLAYER_LOADING, playSessionId });
      // temporarily disable audio player station's stop callback while player is loading because Triton internally calls stream_stop event when switching stations
      AudioPlayerStation.setStopCallback(noop);
      if (AudioPlayerStation.isPlaying) {
        await AudioPlayerStation.stop(false);
      }

      VideoJWPlayer.stop(false);
      dispatch(inlineMediaStop());

      if (isCasting) {
        await ChromeSender.play({
          playSessionId,
          currentOnDemandClip,
          onDemandPlaybackTime,
          onDemandDuration,
          playbackMode,
        });
      } else {
        await AudioPlayerOnDemand.play(playSessionId, currentOnDemandClip, onDemandPlaybackTime).finally(() => {
          AudioPlayerStation.setStopCallback(() => dispatch(playerStop()));
        });
        huaweiPlayerEvents.message('play', currentOnDemandClip);
      }
      dispatch(setMuted(false, false));
    } catch (error) {
      dispatch(playbackError(PLAYBACK_TYPE.ONDEMAND, error, null, playSessionId));
    }
  };
}

export function playLiveAudio() {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const {
      player: { playSessionId, currentStation, isCasting, playbackMode },
      popout: { isPopoutPlayerActive },
    } = getState();

    invariant(currentStation, 'currentStation should be set before calling playLiveAudio()');

    // if popout player is active, only start playing in the popout layer
    if (isPopoutPlayerActive && !isPopoutPlayer) {
      dispatch(setStationInPopout(currentStation, playbackModes.LIVE_AUDIO));
      return;
    }

    invariant(playSessionId, 'playSessionId should be set before calling playLiveAudio()');
    dispatch({ type: playerActions.PLAYER_LOADING, playSessionId });
    // temporarily disable audio player station stop callback while player is loading because Triton internally calls stream_stop event when switching stations
    AudioPlayerStation.setStopCallback(noop);
    if (AudioPlayerOnDemand.isPlaying) {
      await AudioPlayerOnDemand.stop(false);
    }
    VideoJWPlayer.stop(false);
    try {
      dispatch(inlineMediaStop());

      if (isCasting) {
        await ChromeSender.play({
          playSessionId,
          currentStation,
          playbackMode,
        });
      } else {
        await AudioPlayerStation.play(playSessionId, currentStation).finally(() => {
          AudioPlayerStation.setStopCallback(() => dispatch(playerStop()));
        });
        huaweiPlayerEvents.message('play', currentStation);
      }
      dispatch(setMuted(false, false));
      dispatch(playerPlaying());
    } catch (error) {
      if (!isCasting) {
        dispatch(playbackError(PLAYBACK_TYPE.LIVE_AUDIO, error, currentStation, playSessionId));
      }
    }
  };
}

export function playLiveVideo() {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const {
      player: { playSessionId, currentStation, isCasting, playbackMode },
    } = getState();

    invariant(currentStation, 'currentStation should be set before calling playLiveVideo()');
    invariant(playSessionId, 'playSessionId should be set before calling playLiveVideo()');

    try {
      dispatch({ type: playerActions.PLAYER_LOADING, playSessionId });
      if (AudioPlayerStation.isPlaying) {
        await AudioPlayerStation.stop(false);
      }
      if (AudioPlayerOnDemand.isPlaying) {
        await AudioPlayerOnDemand.stop();
      }

      dispatch({ type: playerActions.PLAYER_LOADING, playSessionId });
      dispatch(inlineMediaStop());

      if (isCasting) {
        await ChromeSender.play({
          playSessionId,
          currentStation,
          playbackMode,
        });
      } else {
        await VideoJWPlayer.play(playSessionId, currentStation);
      }

      dispatch(setMuted(false, false));
      dispatch(playerPlaying());
    } catch (error) {
      dispatch(playbackError(PLAYBACK_TYPE.VIDEO, error, currentStation, playSessionId));
    }
  };
}

export function loadOnDemandClip(
  newonDemandClip: PodcastEpisode,
  autoPlay = true
): (dispatch: AppDispatch, getState: () => RootState) => Promise<void> {
  return async (dispatch, getState) => {
    const {
      player: { currentOnDemandClip, isPlaying },
    } = getState();

    dispatch(setPlaybackToOnDemandClip());

    if (currentOnDemandClip && currentOnDemandClip.id === newonDemandClip.id) {
      if (!isPlaying && autoPlay && navigator.onLine) {
        dispatch(playOnDemandClip());
      }
    } else {
      dispatch({
        type: playerActions.PLAYER_LOAD_ON_DEMAND_CLIP,
        onDemandClip: newonDemandClip,
        playSessionId: uuid(),
      });

      if (autoPlay && navigator.onLine) {
        dispatch(playOnDemandClip());
      }
    }
  };
}

export function loadLiveVideo(station: Station, autoPlay = true) {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const {
      player: { currentStation, isPlaying, playbackMode },
    } = getState();
    dispatch(setPlaybackToLiveVideo());

    if (currentStation && currentStation.slug === station.slug) {
      // Start playing the current live video if it was stopped,
      // or play when switching from the live audio mode
      if ((!isPlaying || playbackMode !== playbackMode.LIVE_AUDIO) && autoPlay) {
        if (playbackMode !== playbackModes.LIVE_VIDEO) {
          const playSessionId = uuid();

          dispatch({
            type: playerActions.PLAYER_LOAD_LIVE_VIDEO_STATION,
            playSessionId,
            station,
          });
        }
        dispatch(playLiveVideo());
      }
    } else {
      // Starting a new playback session
      const playSessionId = uuid();
      dispatch({
        type: playerActions.PLAYER_LOAD_LIVE_VIDEO_STATION,
        playSessionId,
        station,
      });

      if (autoPlay) {
        dispatch(playLiveVideo());
      }
    }
  };
}

export function loadStation(station: Station, autoPlay = true, isVideoPlaying = false) {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const {
      player: { currentStation, isPlaying, playbackMode },
    } = getState();

    if (
      playbackMode === playbackModes.LIVE_VIDEO &&
      document.getElementById(VIDEO_PLAYER_ID) &&
      station.videoUrl &&
      !isMobile
    ) {
      dispatch(setIsJWPlayerActive(true));
      dispatch(loadLiveVideo(station, autoPlay));
      return;
    }

    dispatch(setIsJWPlayerActive(false));
    dispatch(setPlaybackToLiveAudio());

    if (
      currentStation?.slug === station.slug &&
      //Collection title can be updated when a experiment between collections is active.
      //In that case a PLAYER_LOAD_STATION needs to be dispatched to update the collectiontitle of the currentStation item in the store
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      currentStation?.collectionTitle === (station as any).collectionTitle
    ) {
      if (station?.videoUrl && !isVideoPlaying && autoPlay && !isMobile) {
        // timeout is needed because jwplayer is loaded asynchronously
        setTimeout(() => {
          dispatch(setIsJWPlayerActive(true));
        }, JW_PLAYER_LOAD_TIMEOUT);

        return;
      }
      // Start playing the current live audio if it was stopped,
      // or play when switching from the live video mode
      if ((!isPlaying || playbackMode !== playbackMode.LIVE_VIDEO) && autoPlay) {
        dispatch(playLiveAudio());
      }
    } else {
      const playSessionId = uuid();
      dispatch({
        type: playerActions.PLAYER_LOAD_STATION,
        playSessionId,
        station,
      });

      if (autoPlay) {
        if (station.videoUrl && !isVideoPlaying && !isMobile) {
          // timeout is needed because jwplayer is loaded asynchronously
          setTimeout(() => {
            dispatch(setIsJWPlayerActive(true));
          }, JW_PLAYER_LOAD_TIMEOUT);
          return;
        }
        dispatch(playLiveAudio());
      }
    }
  };
}

export function play() {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const {
      player: { playbackMode, currentStation },
      jwPlayer: { isActive },
    } = getState();

    if (currentStation?.videoUrl && !isActive && !isMobile) {
      setTimeout(() => {
        dispatch(setIsJWPlayerActive(true));
      }, JW_PLAYER_LOAD_TIMEOUT);
      return;
    }

    switch (playbackMode) {
      case playbackModes.LIVE_AUDIO:
        dispatch(playLiveAudio());
        break;
      case playbackModes.LIVE_VIDEO:
        dispatch(playLiveVideo());
        break;
      case playbackModes.ON_DEMAND_CLIP:
        dispatch(playOnDemandClip());
        break;
      default:
        break;
    }
  };
}

export function stop(trackAnalytics = true) {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const {
      player: { playbackMode, isCasting },
    } = getState();

    if (isCasting) {
      ChromeSender.stop();
    } else {
      switch (playbackMode) {
        case playbackModes.LIVE_AUDIO:
          await AudioPlayerStation.stop(trackAnalytics);
          huaweiPlayerEvents.message('stop');
          break;
        case playbackModes.LIVE_VIDEO:
          VideoJWPlayer.stop(trackAnalytics);
          break;
        case playbackModes.ON_DEMAND_CLIP:
          await AudioPlayerOnDemand.stop(trackAnalytics);
          huaweiPlayerEvents.message('stop');
          break;
        default:
          break;
      }
    }
    dispatch(playerStop());
  };
}

/**
 * loads station from query params or localStorage
 * also sets volume
 * if the station is present in url it will load that one instead of the one from localStorage
 * and will also play video if that is defined as type in url and video is available
 */
export function loadPlayItemWithParameters() {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const { player, urlParams } = getState();

    dispatch(setVolumeLevel(player.volumeLevel, false));
    let stationParams = null;
    let mediaType = null;
    if ('station' in urlParams) {
      stationParams = urlParams.station;
      if ('type' in urlParams) {
        mediaType = urlParams.type;
      }
      const station = await graphql.fetchStation({ slug: stationParams });
      if (station) {
        if (mediaType === 'video' && 'videoUrl' in station) {
          dispatch(
            showNotification({
              message: NotificationTexts.PLAYBACK_LIVE_VIDEO_FROM_URL,
            })
          );
          dispatch(loadLiveVideo(station));
        } else {
          dispatch(loadStation(station, false));
        }
      }
    }
  };
}
