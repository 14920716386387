import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ContentPageLayout from 'components/ContentPageLayout';
// import LoadingPage from 'components/pages/status/LoadingPage';
import NotFoundPage from 'components/pages/status/NotFoundPage';
import UnknownError from 'components/pages/status/UnknownError';
import OfflinePage from 'components/pages/status/OfflinePage';

const ContentPage = ({ ...contentPageProps }) => (
  <ContentPageLayout>
    <ContentPageRenderer {...contentPageProps} />
  </ContentPageLayout>
);

export default ContentPage;

class ContentPageRenderer extends PureComponent {
  static defaultProps = {
    error: null,
    match: null,
    page: null,
    contentType: '',
    ContentPageComponent: null,
  };

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isOnline: PropTypes.bool.isRequired,
    error: PropTypes.object,
    match: PropTypes.object,
    page: PropTypes.object,
    contentType: PropTypes.string,
    ContentPageComponent: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object, // DynamicPage is exporting redux connect
    ]),
  };

  render() {
    const { isLoading, error, match, page, contentType, ContentPageComponent, isOnline } = this.props;

    if (!isOnline) {
      return <OfflinePage />;
    }

    if (!error && page && ContentPageComponent) {
      return <ContentPageComponent {...page} urlMatch={match} />; // Serve Page as early as possible
    }

    if (!error && contentType === 'podcast' && ContentPageComponent) {
      return (
        <ContentPageComponent
          contentTypeId="podcast"
          slug={match.params.slug}
          subSlug={match.params.subSlug}
          isComponentLoading={isLoading}
        />
      );
    }

    if (!error && contentType === 'clip' && ContentPageComponent) {
      return (
        <ContentPageComponent contentTypeId="clip" subSlug={match.params.subSlug} isComponentLoading={isLoading} />
      );
    }

    // if (isLoading && !error) {
    //   return <LoadingPage />;
    // }

    if (error && isOnline) {
      return <UnknownError />;
    }

    if (!page) {
      return <NotFoundPage />;
    }

    return <UnknownError />;
  }
}
