export const contentActions = {
  FETCH_CONTENT_REQUEST: 'FETCH_CONTENT_REQUEST',
  FETCH_CONTENT_SUCCESS: 'FETCH_CONTENT_SUCCESS',
  FETCH_PAGES_SUCCESS: 'FETCH_PAGES_SUCCESS',
  FETCH_CONTENT_FAILURE: 'FETCH_CONTENT_FAILURE',
  FETCH_COLLECTION_SUCCES: 'FETCH_COLLECTION_SUCCES',
  UPDATE_PAGE_CONTENT: 'UPDATE_PAGE_CONTENT',
  IS_ONLINE: 'IS_ONLINE',
  IS_OFFLINE: 'IS_OFFLINE',
  SET_CURRENT_PAGE_SLUG: 'SET_CURRENT_PAGE_SLUG',
  SET_TILE_AMOUNT: 'SET_TILE_AMOUNT',
};

export const healthyPlayActions = {
  HEALTHY_PLAY_STATION: 'HEALTHY_PLAY_STATION',
  HEALTHY_PLAY_ON_DEMAND_CLIP: 'HEALTHY_PLAY_ON_DEMAND_CLIP',
};

export const messageModalActions = {
  SET_MESSAGE_MODAL_OPEN: 'SET_MESSAGE_MODAL_OPEN',
  SET_MESSAGE_MODAL_CLOSE: 'SET_MESSAGE_MODAL_CLOSE',
};

export const modalActions = {
  SET_MODAL_OPEN: 'SET_MODAL_OPEN',
  SET_MODAL_CLOSE: 'SET_MODAL_CLOSE',
  SET_MODAL_STANDALONE_OPEN: 'SET_MODAL_STANDALONE_OPEN',
  SET_MODAL_STANDALONE_CLOSE: 'SET_MODAL_STANDALONE_CLOSE',
};

export const navigationActions = {
  SET_NAVIGATION: 'SET_NAVIGATION',
  OPEN_NAVIGATION: 'OPEN_NAVIGATION',
  CLOSE_NAVIGATION: 'CLOSE_NAVIGATION',
  TOGGLE_NAVIGATION: 'TOGGLE_NAVIGATION',
  SET_SUB_NAVIGATION_MENU: 'SET_ACTIVE_SUB_NAVIGATION',
  OPEN_SUB_NAVIGATION: 'OPEN_SUB_NAVIGATION',
  CLOSE_SUB_NAVIGATION: 'CLOSE_SUB_NAVIGATION',
  SET_SCROLL_SHOW_NAVIGATION: 'SET_SCROLL_SHOW_NAVIGATION',
  SET_SCROLL_HIDE_NAVIGATION: 'SET_SCROLL_HIDE_NAVIGATION',
  SET_HAS_MOBILE_HEADER: 'SET_HAS_MOBILE_HEADER',
  SET_MOBILE_LIGHT_NAVIGATION: 'SET_MOBILE_LIGHT_NAVIGATION',
};

export const notificationActions = {
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
};

export const playerActions = {
  PLAYER_INITIALIZED: 'PLAYER_INITIALIZED',
  PLAYER_STOPPED: 'PLAYER_STOPPED',
  PLAYER_PLAYING: 'PLAYER_PLAYING',
  PLAYER_SET_PLAYBACK_TO_LIVE_AUDIO: 'PLAYER_SET_PLAYBACK_TO_LIVE_AUDIO',
  PLAYER_SET_PLAYBACK_TO_LIVE_VIDEO: 'PLAYER_SET_PLAYBACK_TO_LIVE_VIDEO',
  PLAYER_SET_PLAYBACK_TO_ON_DEMAND_CLIP: 'PLAYER_SET_PLAYBACK_TO_ON_DEMAND_CLIP',
  PLAYER_SKIP_ON_DEMAND_CLIP_FORWARD: 'PLAYER_SKIP_ON_DEMAND_CLIP_FORWARD',
  PLAYER_SKIP_ON_DEMAND_CLIP_BACKWARD: 'PLAYER_SKIP_ON_DEMAND_CLIP_BACKWARD',
  PLAYER_SET_ON_DEMAND_TIME: 'PLAYER_SET_ON_DEMAND_TIME',
  PLAYER_TRACK_ON_DEMAND_PLAYBACK_TIME: 'PLAYER_TRACK_ON_DEMAND_PLAYBACK_TIME',
  PLAYER_EMPTY_ON_DEMAND_PLAYBACK: 'PLAYER_EMPTY_ON_DEMAND_PLAYBACK',
  PLAYER_SET_ON_DEMAND_PLAYBACK_DURATION: 'PLAYER_SET_ON_DEMAND_PLAYBACK_DURATION',
  PLAYER_SET_UPCOMING_TRACKS: 'PLAYER_SET_UPCOMING_TRACKS',
  PLAYER_SET_FULL_PLAYER: 'PLAYER_SET_FULL_PLAYER',
  PLAYER_FORCE_MINI_PLAYER: 'PLAYER_FORCE_MINI_PLAYER',
  PLAYER_HIDE_MINI_PLAYER: 'PLAYER_HIDE_MINI_PLAYER',
  PLAYER_HIDE_PLAYER: 'PLAYER_HIDE_PLAYER',
  PLAYER_SET_ON_DEMAND_PLAYBACK_TIME: 'PLAYER_SET_ON_DEMAND_PLAYBACK_TIME',
  PLAYER_ERRORED: 'PLAYER_ERRORED',
  PLAYER_LOADING: 'PLAYER_LOADING',
  PLAYER_LOAD_ON_DEMAND_CLIP: 'PLAYER_LOAD_ON_DEMAND_CLIP',
  PLAYER_LOAD_LIVE_VIDEO_STATION: 'PLAYER_LOAD_LIVE_VIDEO_STATION',
  PLAYER_LOAD_STATION: 'PLAYER_LOAD_STATION',
  PROGRESS_UPDATE: 'PROGRESS_UPDATE',
  CASTING_AVAILABLE: 'CASTING_AVAILABLE',
  CASTING_UNAVAILABLE: 'CASTING_UNAVAILABLE',
  PLAYER_CASTING: 'PLAYER_CASTING',
  PLAYER_CASTING_STOPPED: 'PLAYER_CASTING_STOPPED',
  SET_PLAYER_TYPE: 'SET_PLAYER_TYPE',
  TOGGLE_POPOUT_PLAYER: 'TOGGLE_POPOUT_PLAYER',
  IS_POPOUT_PLAYER: 'IS_POPOUT_PLAYER',
};

export const popoutActions = {
  POPOUT_SET_STATION: 'POPOUT_SET_STATION',
  POPOUT_SET_CLIP: 'POPOUT_SET_CLIP',
  TOGGLE_POPOUT_PLAYER: 'TOGGLE_POPOUT_PLAYER',
  POPOUT_SET_PLAYBACK_TIME: 'POPOUT_SET_PLAYBACK_TIME',
  POPOUT_SET_PLAYBACK_DURATION: 'POPOUT_SET_PLAYBACK_DURATION',
};

export const inlineMediaActions = {
  INLINE_MEDIA_STOPPED: 'INLINE_MEDIA_STOPPED',
  INLINE_MEDIA_PAUSED: 'INLINE_MEDIA_PAUSED',
  INLINE_MEDIA_PLAYING: 'INLINE_MEDIA_PLAYING',
  INLINE_MEDIA_ERRORED: 'INLINE_MEDIA_ERRORED',
};

export const playerHintActions = {
  PLAYER_SET_PLAYER_HINT: 'PLAYER_SET_PLAYER_HINT',
};

export const playerVolumeActions = {
  SET_VOLUME_LEVEL: 'SET_VOLUME_LEVEL',
  SET_MUTED: 'SET_MUTED',
};

export const playoutActions = {
  PLAYOUT_START: 'PLAYOUT_START',
  PLAYOUT_END: 'PLAYOUT_END',
};

export const playoutHistoryActions = {
  POPULATE_PLAYOUT_HISTORY: 'POPULATE_PLAYOUT_HISTORY',
};

export const searchActions = {
  HIDE_SEARCH: 'HIDE_SEARCH',
  SET_SEARCH_ACTIVE: 'SET_SEARCH_ACTIVE',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_SEARCH_RESULTS_ALGOLIA: 'SET_SEARCH_RESULTS_ALGOLIA',
  SET_SEARCH_RECENT: 'SET_SEARCH_RECENT',
  SEARCH_IS_LOADING: 'SEARCH_IS_LOADING',
  SET_USE_GRAPHQL_SEARCH: 'SET_USE_GRAPHQL_SEARCH',
};

export const persistActions = {
  REHYDRATE: 'persist/REHYDRATE',
};

export const trackingActions = {
  USER_ON_JUKE: 'USER_ON_JUKE',
  USER_NOT_ON_JUKE: 'USER_NOT_ON_JUKE',
};

export const PLAYER_TYPES = {
  MINI_PLAYER: 'miniplayer',
  SLIDE_IN_PLAYER: 'slide_in_playter',
  FULL_PLAYER: 'full_player',
};

export const podcastFoldActions = {
  OPEN_FOLD: 'OPEN_FOLD',
  CLOSE_FOLD: 'CLOSE_FOLD',
};

export const ratingActions = {
  SET_APP_RATING: 'SET_APP_RATING',
};

export const paletteActions = {
  SET_PALETTE: 'SET_PALETTE',
};

export const jwPlayerActions = {
  SET_IS_ACTIVE: 'SET_IS_ACTIVE',
};

export const gigyaRouterActions = {
  GIGYA_SCREEN_OPENED: 'GIGYA_SCREEN_OPENED',
  GIGYA_SCREEN_CLOSED: 'GIGYA_SCREEN_CLOSED',
};
