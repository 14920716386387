import React from 'react';
import Schema from 'components/Schema';
import PropTypes from 'prop-types';

const PageSchema = (props) => {
  let { type, name, logo, description, id, image, keywords } = props;

  if (!type || !id) {
    return null;
  }

  let url = `https://juke.nl${id.startsWith('/') ? id : '/' + id}`;

  if (type === 'default' && id === 'podcasts') {
    // PodcastsSchema
    return null;
  }

  if (type === 'page' && name === 'Apparaten') {
    type = 'apparaten';
    const splitLocation = window.location.pathname.split('/');
    const [head, ...title] = splitLocation[splitLocation.length - 1];
    name = `${head?.toUpperCase()}${title?.join('')}`;
    url = `https://juke.nl${window.location.pathname}`;
  }

  let schema = {
    '@context': 'http://schema.org',
    '@type': type,
    logo,
    description,
    name,
    image,
    url,
    keywords,
  };

  switch (type) {
    case 'clip':
      schema = {
        ...schema,
        '@type': 'Clip',
        url: `https://juke.nl${window.location.pathname}`,
      };
      break;
    case 'RadioStation':
      schema = {
        ...schema,
        areaServed: 'NL',
        priceRange: 'Gratis',
      };
      break;
    case 'genrePage':
    case 'brand':
      schema = {
        ...schema,
        '@type': 'CollectionPage',
      };
      break;
    case 'radiostation':
      schema = {
        ...schema,
        '@type': 'ItemPage',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Hilversum, Nederland',
          postalCode: '1217 SC',
          streetAddress: 'Bergweg 70',
        },
        telephone: '035 538 5538',
      };
      break;
    case 'apparaten':
      schema = {
        ...schema,
        '@type': 'ItemPage',
      };
      break;
    case 'podcast':
      schema = {
        ...schema,
        '@type': 'PodcastSeries',
      };
    case 'default':
      // its home
      schema = {
        ...schema,
        '@type': 'WebPage',
        url: 'https://juke.nl/',
        name: 'JUKE',
      };
      break;
    default:
      break;
  }

  return (
    <Schema
      data={{
        ...schema,
        id: schema.url,
      }}
    />
  );
};

PageSchema.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
};

PageSchema.defaultProps = {
  type: null,
  name: null,
  logo: null,
  description: null,
  id: null,
  image: null,
  keywords: null,
};

export default PageSchema;
