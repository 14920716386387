import Collection from './Collection';
import { isEmpty, capitalizeFirstLetter } from 'util/index';
import { UniversalCollection, UniversalConfig, UniversalNavigationCollection } from '../interfaces';
import FOOTER from '../../components/Footer/Footer.const';
import Navigation from './Navigation';

/**
 * @class
 * A representation of a NavigationCollection
 *
 * @param {object} collection - collection model
 */
export default class NavigationCollection extends Collection {
  items: Array<Navigation>;
  variant = FOOTER.ORDER_SECONDARY;

  constructor(collection: UniversalCollection, config?: UniversalConfig[], options?: string[]) {
    super(collection, config, options);

    if (isEmpty(collection)) {
      return null;
    }

    const navigationCollection = <UniversalNavigationCollection>collection;

    this.items = navigationCollection?.getNavigations?.navigations?.map((navigation) => new Navigation(navigation));
    const capitalizedContentTypeId = capitalizeFirstLetter(this.contentTypeId);
    this.contentTypeId = `footer${capitalizedContentTypeId}`;

    if (!isEmpty(navigationCollection?.options)) {
      if (navigationCollection.options[0] === FOOTER.ORDER_PRIMARY) {
        this.variant = FOOTER.ORDER_PRIMARY;
      }
    }

    // Make this class read-only
    Object.freeze(this);
  }
}
