import React from 'react';
import { contains, isEmpty, logger } from 'util/index';

import withGraphqlData from 'components/hoc/withGraphqlData';

import AccordionList from 'components/AccordionList';
import Banner from 'components/Banner';
import MopinionForm from 'components/MopinionForm';

import CallToActionBlock from 'components/CallToActionBlock';
import CardDeck from 'components/CardDeck';
import Composite from 'components/Composite';
import FeaturedPodcasts from 'components/FeaturedPodcasts';
import IFrame from 'components/IFrame';
import Image from 'components/Image';
import OnDemandCollection from 'components/OnDemandSection';
import Quote from 'components/Quote';
import RecentlyPlayedStationsCollection from 'components/RecentlyPlayedStationsCollection';
import FooterNavigationCollection from 'components/FooterNavigationCollection';
import RichText from 'components/RichText';
import Tabs from 'components/Tabs';
import TextFrame from 'components/TextFrame';
import Video from 'components/Video';
import Overview from 'components/Overview';
import Collection from 'components/Collection';
import CONTENT_TYPE_ID from 'globalConst/contentTypes-const';
import { default as CONTENT_TYPES_CONST } from 'globalConst/contentTypes-const';

import { TILE_COMPONENT_TYPES } from '../TileComponent/TileComponent.const';
import withPlayouts from '../hoc/withPlayouts';

type ContentTypeComponent = {
  slug?: string;
  contentTypeId: string;
  items?: Array<unknown>;
  showEmptyState?: boolean;
  store?: boolean;
};

const ContentTypeComponent = (props: ContentTypeComponent): JSX.Element => {
  const { contentTypeId, store = false } = props;
  const COLLECTION_CONTENT_TYPES = {
    stationCollection: TILE_COMPONENT_TYPES.STATION,

    genreCollection: TILE_COMPONENT_TYPES.TAG,
    moodCollection: TILE_COMPONENT_TYPES.TAG,
    tagCollection: TILE_COMPONENT_TYPES.TAG,

    showCollection: TILE_COMPONENT_TYPES.SHOW,
    promotionCollection: TILE_COMPONENT_TYPES.PROMOTION,

    onDemandCollection: TILE_COMPONENT_TYPES.ON_DEMAND,
    searchClipCollection: TILE_COMPONENT_TYPES.ON_DEMAND,
    continueListeningClipCollection: TILE_COMPONENT_TYPES.ON_DEMAND,

    podcastCollection: TILE_COMPONENT_TYPES.PODCAST,

    recentlyPlayedStationsCollection: TILE_COMPONENT_TYPES.RECENTLY_PLAYED_STATION,
    mixedCollection: TILE_COMPONENT_TYPES.MIXED_CONTENT,
    clipCollection: TILE_COMPONENT_TYPES.LIST,
  };

  // Check if collection components exists
  let tileComponent = null;
  if (
    contains(contentTypeId, 'collection') &&
    !contains([CONTENT_TYPES_CONST.FOOTER_NAVIGATION_COLLECTION], contentTypeId)
  ) {
    if (!contains(Object.keys(COLLECTION_CONTENT_TYPES), contentTypeId) && contentTypeId !== 'overviewCollection') {
      logger.warn(`The dynamic component ${contentTypeId} could not be rendered.`);
      return null;
    }
    tileComponent = COLLECTION_CONTENT_TYPES[contentTypeId];
  }

  const CONTENT_TYPES = {
    //TODO: Remove?
    banner: Banner,

    //TODO: Remove?
    cardDeck: CardDeck,

    accordionList: AccordionList,
    callToActionBlock: CallToActionBlock,
    composite: Composite,
    featuredPodcasts: FeaturedPodcasts,
    iFrame: IFrame,
    image: Image,
    tagCollection: Collection,
    genreCollection: Collection,
    moodCollection: Collection,
    mopinionForm: MopinionForm,
    onDemandCollection: OnDemandCollection,
    podcastCollection: Collection,
    continueListeningClipCollection: withGraphqlData(Collection),
    quote: Quote,
    richText: RichText,
    showCollection: Collection,
    stationCollection: withPlayouts(Collection),
    recentlyPlayedStationsCollection: RecentlyPlayedStationsCollection,
    tabs: Tabs,
    textFrame: TextFrame,
    video: Video,
    promotionCollection: Collection,
    mixedCollection: Collection,
    searchClipCollection: Collection,
    clipCollection: Collection,
    footerNavigationCollection: FooterNavigationCollection,
    // overview: disableLazyFetch ? Overview : withGraphQL(Overview),
    // overviewCollection: disableLazyFetch ? Overview : withGraphQL(Overview),
    overview: Overview,
    overviewCollection: Overview,
    search: Overview,
  };
  // console.log('[] contentTypeId', contentTypeId);
  let Component = CONTENT_TYPES[contentTypeId];
  const storeData = store || contains([CONTENT_TYPE_ID.OVERVIEW_COLLECTION, CONTENT_TYPE_ID.OVERVIEW], contentTypeId);
  if (isEmpty(Component)) {
    return null;
  }

  // TODO: Remove this part after all pages are initialized with overview
  // Note: Home mobile variant
  if (
    isEmpty(props.items) &&
    contentTypeId !== 'recentlyPlayedStationsCollection' &&
    !isEmpty(props.slug) &&
    !props.showEmptyState &&
    !contains(props.contentTypeId, 'favorite')
  ) {
    Component = withGraphqlData(Component);
  }
  return React.createElement(Component, {
    ...props,
    store: storeData,
    tileComponent,
  });
};

export default React.memo(ContentTypeComponent);
