import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';

import classNames from 'classnames';

import { SCROLL_CONTAINER_ID, SHARE_MENU_ID, TABS_HEADER_PORTAL_ID } from 'globalConst/const';
// import { preventBodyScrolling } from 'util/noscroll';
// import routingVariables from 'routingVariables.json';
import { isMobile, isTouch } from 'util/device';

import asContentPage from 'components/hoc/asContentPage';
import MODAL_ID from 'components/Modal/Modal.const';

import DynamicPage from 'components/pages/DynamicPage';

import LinkContentPage from 'components/pages/content/LinkContentPage';

import Footer from 'components/Footer';
import ShowPage from 'components/pages/content/ShowPage';
import FeedbackCompletePage from 'components/pages/content/FeedbackCompletePage';
import NotFoundPage from 'components/pages/status/NotFoundPage';
import OfflinePage from 'components/pages/status/OfflinePage';
import CampaignPage from 'components/pages/content/CampaignPage';
import AudiobookPage from 'components/pages/content/AudiobookPage';
import { createUseStyles } from 'react-jss';
import styles from './Content.styles';
import * as cssVariable from 'cssVariables';

const NotFoundPageHOC = asContentPage('notFoundPage', NotFoundPage);
const OfflinePageHOC = asContentPage('offlinePage', OfflinePage);

export const routeComponents = {
  HOME_PAGE: DynamicPage,
  SHOW_PAGE: ShowPage,
  STATION_PAGE: LinkContentPage,
  NON_STATION_BRAND_PAGE: LinkContentPage,
  CAMPAIGN_PAGE: CampaignPage,
  GENRE_PAGE: LinkContentPage,
  MOODS_EN_MOMENTS_PAGE: LinkContentPage,
  AUDIOBOOK_PAGE: AudiobookPage,
  DYNAMIC_PAGE: DynamicPage,
  FEEDBACK_COMPLETE_PAGE: FeedbackCompletePage,
  PODCAST_PAGE: LinkContentPage,
  PODCAST_EPISODE_PAGE: LinkContentPage,
  PODCAST_GENRE_PAGE: LinkContentPage,
};

const useStyles = createUseStyles(styles, { name: 'Content' });
const UnstyledContent = (props) => {
  const {
    hasMobileHeader,
    modalIsOpen,
    modalStandaloneIsOpen,
    searchIsActive,
    isInMobileApp,
    // setCurrentPageSlug,
    setTileAmount,
  } = props;
  const classes = useStyles();
  // const routes = useRef();
  const containerRef = useRef();
  const modalportal = useRef();

  // useEffect(() => {
  //   // Creates the routes using the routingVariables (a global config for routing)
  //   // Routing variables won't change so we can create this in the component's constructor
  //   routes.current = createRoutes(routingVariables);
  // }, []);

  useEffect(() => {
    setContainerWidth();
    window.addEventListener('resize', setContainerWidth);
    return () => {
      window.removeEventListener('resize', setContainerWidth);
    };
  }, [containerRef.current]);

  /**
   * Returns a JSX component based on current route and adds routeType to its props
   *
   * @param {object} routeProps
   * @param {object} route
   * @returns {JSX}
   */
  // const handleRouteRender = (routeProps, route) => {
  //   const {
  //     params: { slug, subSlug },
  //     path: currentPath,
  //   } = routeProps.match;
  //   setCurrentPageSlug(currentPath === '/' ? 'home' : slug, subSlug);

  //   const Component = routeComponents[route.type];

  //   return <Component routeType={route.type} {...routeProps} />;
  // };

  const setContainerWidth = () => {
    if (!containerRef || !containerRef.current) {
      return;
    }
    const { width } = containerRef.current.getBoundingClientRect();

    const contentWidth = width - cssVariable.contentOffset;
    if (width > 0) {
      setTileAmount(contentWidth);
    }
  };
  /**
   * Returns a list of JSX routing components using the routing property
   *
   * @param {object} routing
   * @returns {array}
   */
  // const createRoutes = (routing) => {
  //   if (routing && routing.routes) {
  //     return routing.routes.map((route) => (
  //       <Route exact key={route.path} path={route.path} render={(routeProps) => handleRouteRender(routeProps, route)} />
  //     ));
  //   }

  //   return [];
  // };

  // preventBodyScrolling(modalIsOpen || modalStandaloneIsOpen, modalportal);

  return (
    <div
      ref={containerRef}
      className={classNames(classes.container, {
        [classes.overflowVisible]: modalIsOpen,
        [classes.hasMobileHeader]: hasMobileHeader,
        [classes.hasMobileHeaderExtraTop]: hasMobileHeader && !modalStandaloneIsOpen && (!modalIsOpen || isMobile),
      })}
    >
      <ScrollMemory elementID={SCROLL_CONTAINER_ID} />

      <div
        id={SCROLL_CONTAINER_ID}
        className={classNames(classes.contentSection, {
          [classes.hide]: modalIsOpen,
          [classes.hideDownSl]: searchIsActive && hasMobileHeader,
          [classes.contentSectionScrollable]: !isTouch,
        })}
      >
        <div className={classes.contentPlaceholder}>
          <Switch>
            <Route exact path="/" component={DynamicPage} />
            <Route component={NotFoundPageHOC} />
            <Route component={OfflinePageHOC} />
          </Switch>
        </div>
        {!searchIsActive && !isInMobileApp && <Footer />}
      </div>
      <div
        id={MODAL_ID}
        ref={modalportal}
        className={classNames(classes.modalPortal, {
          [classes.showModal]: modalIsOpen || modalStandaloneIsOpen,
          [classes.showModalStandalone]: modalStandaloneIsOpen,
        })}
      ></div>
      <div id={SHARE_MENU_ID} />
      <div id={TABS_HEADER_PORTAL_ID} />
    </div>
  );
};

UnstyledContent.propTypes = {
  hasMobileHeader: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
  modalStandaloneIsOpen: PropTypes.bool,
  searchIsActive: PropTypes.bool.isRequired,
  isInMobileApp: PropTypes.bool,
  setCurrentPageSlug: PropTypes.func,
  setTileAmount: PropTypes.func,
  mobileLightNavigationItems: PropTypes.array,
};

UnstyledContent.defaultProps = {
  hasMobileHeader: false,
  isInMobileApp: false,
  setTileAmount: null,
  setCurrentPageSlug: null,
  mobileLightNavigationItems: null,
  modalStandaloneIsOpen: false,
};

export default UnstyledContent;
