// import { lastActivityExpireTime } from 'globalConst/const';

export default class UserSessionManager {
  constructor() {
    this.addEventListenersForUserActions();
  }

  resetUserSessionForEvents = (): void => this.resetUserSession();

  resetUserSession = (isHeartbeatStop = false): void => {
    if (isHeartbeatStop) {
      this.addEventListenersForUserActions();
    }

    const expireTime = new Date();
    expireTime.setMinutes(expireTime.getMinutes() + 30);
    // localStorage.setItem(lastActivityExpireTime, expireTime.toString());
  };

  resetSessionOnVisibilityChange = (): void => {
    if (document.visibilityState !== 'hidden') {
      this.resetUserSession();
    }
  };

  addEventListenersForUserActions = (): void => {
    document.addEventListener('mousemove', this.resetUserSessionForEvents);
    document.addEventListener('keydown', this.resetUserSessionForEvents);
    document.addEventListener('click', this.resetUserSessionForEvents);
    document.addEventListener('wheel', this.resetUserSessionForEvents);
    window.addEventListener('resize', this.resetUserSessionForEvents);
    document.addEventListener('touchmove', this.resetUserSessionForEvents);
    document.addEventListener('visibilitychange', this.resetSessionOnVisibilityChange);
  };

  removeEventListenersForUserActions = (): void => {
    document.removeEventListener('mousemove', this.resetUserSessionForEvents);
    document.removeEventListener('keydown', this.resetUserSessionForEvents);
    document.removeEventListener('click', this.resetUserSessionForEvents);
    document.removeEventListener('wheel', this.resetUserSessionForEvents);
    window.removeEventListener('resize', this.resetUserSessionForEvents);
    document.removeEventListener('touchmove', this.resetUserSessionForEvents);
    document.removeEventListener('visibilitychange', this.resetSessionOnVisibilityChange);
  };
}
