import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectStyles } from 'injectStyles';
import classNames from 'classnames';

import getMobileLightVariant from 'util/getMobileLightVariant';
import { noop } from 'util/index';

import { BreadcrumbsSchema, DevicesSchema } from 'components/Schema';

import PageContent from 'components/PageContent';
import Video from 'components/Video';
import Image from 'components/Image';

import ContentTypeComponent from '../../ContentTypeComponent';
import layoutTypes from 'components/CardDeck/Card/Card.const';

const content = [
  [
    { url: 'https://www.radio10.nl/zenders/radio-10', title: 'Radio 10', image: 'radio10_600x600.jpg' },
    {
      url: 'https://www.radio10.nl/zenders/radio-10-60s-en-70s-hits',
      title: 'Radio 10 60s & 70s Hits',
      image: 'radio10_60s_70s_600x600.jpg',
    },
    {
      url: 'https://www.radio10.nl/zenders/radio-10-80s-hits',
      title: 'Radio 10 80s Hits',
      image: 'radio10_80s_600x600.jpg',
    },
    {
      url: 'https://www.radio10.nl/zenders/radio-10-non-stop',
      title: 'Radio 10 Non-Stop',
      image: 'radio10_nonstop_600x600.jpg',
    },
  ],
  [
    { url: 'https://www.538.nl/radio-luisteren', title: 'RADIO 538', image: '538_600x600.jpg' },
    { url: 'https://www.538.nl/classics', title: 'RADIO 538 CLASSICS', image: '538_classics_600x600.jpg' },
    { url: 'https://www.538.nl/538-non-stop', title: 'RADIO 538 NON-STOP', image: '538_nonstop_600x600.jpg' },
    { url: 'https://www.538.nl/538-top-50', title: 'RADIO 538 TOP 50', image: '538_top50_600x600.jpg' },
  ],
  [
    { url: 'https://www.skyradio.nl/online-radio', title: 'Sky Radio', image: 'skyradio_600x600.jpg' },
    {
      url: 'https://www.skyradio.nl/zenders/sky-radio-lovesongs',
      title: 'Sky Radio Lovesongs',
      image: 'skyradio_lovesongs_600x600.jpg',
    },
    {
      url: 'https://www.skyradio.nl/zenders/sky-radio-nice-en-easy',
      title: 'Sky Radio Nice & Easy',
      image: 'skyradio_nice_easy_600x600.jpg',
    },
    {
      url: 'https://www.skyradio.nl/zenders/sky-radio-hits',
      title: 'Sky Radio',
      image: 'skyradio_hits_600x600.jpg',
    },
  ],
];

const page = {
  uuid: '643a27ab-d3d9-483d-bcd6-57ce330f30a0',
  contentTypeId: 'page',
  title: 'Welkom bij JUKE',
  slug: 'home',
  pageType: 'default',

  tags: [],
  name: 'Home - web desktop!',
  content: content.map((items, index) => ({
    uuid: `item-${index}`,
    contentTypeId: 'cardDeck',
    title: items[0].title,
    showTitle: false,
    useCarousel: false,
    minWidthItems: 200,
    minWidthItemsMobile: 180,
    cards: items.map(({ title, url, image }) => ({
      uuid: url,
      contentTypeId: 'card',
      title: title,
      showTitle: false,
      image: {
        uuid: '32401279-a713-40b0-a6c3-0184b5951e18',
        contentTypeId: 'media',
        title: title,
        mimeType: 'image/jpeg',
        file: image,
        url: `${process.env.PUBLIC_URL}/img/${image}`,
        altText: null,
      },
      layout: layoutTypes.default,
      buttonText: 'Luister',
      url: `${url}?utm_source=juke&utm_medium=referral&utm_campaign=juke_redirect`,
      preferHD: false,
    })),
  })),
};

class DynamicPage extends PureComponent {
  static defaultProps = {
    page: {
      title: null,
      content: null,
      headerVideo: {},
      articleImage: {},
      showSocial: false,
      isArticlePage: false,
      hidePlayer: false,
    },
    subSlug: null,
    parentSlug: null,
    subtitle: null,
    description: null,
    forceMiniPlayerState: noop,
    pageTitle: null,
    rating: null,
    deeplink: null,
  };

  static propTypes = {
    page: PropTypes.object,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    classes: PropTypes.object.isRequired,
    subSlug: PropTypes.string,
    parentSlug: PropTypes.string,
    forceMiniPlayerState: PropTypes.func,
    pageTitle: PropTypes.string,
    rating: PropTypes.object,
    deeplink: PropTypes.string,
  };

  state = {
    tabsTitle: null,
  };

  mobileLightVariant = getMobileLightVariant();

  componentDidMount() {
    const {
      page: { hidePlayer },
    } = this.props;
    if (hidePlayer) {
      this.props.forceMiniPlayerState(true);
    }

    this.getCurrentTabTitle();
  }

  componentDidUpdate() {
    this.getCurrentTabTitle();
  }

  componentWillUnmount() {
    const { hidePlayer } = this.props.page;
    if (hidePlayer) {
      this.props.forceMiniPlayerState(false);
    }
  }

  getCurrentTabTitle = () => {
    const { page } = this.props;

    const subSlug = window.location.pathname.split('/').pop();
    const matchingTabs = (page.content || []).find(
      ({ contentTypeId, tabs }) => contentTypeId === 'tabs' && tabs.some((tab) => tab.slug === subSlug)
    );
    const tabsTitle = matchingTabs && matchingTabs.tabs.find((tab) => tab.slug === subSlug).title;
    this.setState({ tabsTitle });
  };

  getMetadata = () => {
    const { subSlug } = this.props;
    const { metadata } = page || {};

    /**
     * Used for Tabs with Indexable Tabs
     * If we have a sub slug in the url we should use the the metadata of the matching tab if available
     */
    if (subSlug) {
      const matchingTabs = (page.content || []).find(
        ({ contentTypeId, tabs }) => contentTypeId === 'tabs' && tabs.some((tab) => tab.slug === subSlug)
      );
      const tabMetadata = matchingTabs && matchingTabs.tabs.find((tab) => tab.slug === subSlug).metadata;
      if (tabMetadata) {
        return tabMetadata;
      }
    }
    return metadata;
  };

  render() {
    const { pageTitle, parentSlug, subSlug, rating, classes } = this.props;
    const { slug, content, title } = page || {};
    const { tabsTitle } = this.state;
    return (
      <div
        className={classNames(classes.page, {
          [classes.articlePageContent]: page.isArticlePage,
        })}
      >
        {/* <PageTypeSchema type={pageTypeSchema} metadata={metadata} /> */}
        {/* <DeeplinkSchema deeplinks={[{ link: deeplink }]} /> */}
        {/* <PageHeader {...this.props} metadata={metadata} /> */}
        <BreadcrumbsSchema level2={parentSlug ? pageTitle : title} level3={tabsTitle || (parentSlug && title)} />
        {page.headerVideo && page.headerVideo.url ? <Video {...page.headerVideo} /> : null}
        {page.articleImage && page.articleImage.url ? <Image media={page.articleImage} /> : null}
        <DevicesSchema rating={rating} slug={slug} subSlug={subSlug} />
        <PageContent isArticlePage={page.isArticlePage}>
          {page.description && page.isArticlePage && (
            <div
              className={classNames(classes.componentWrapperInArticlePage, classes.articlePageDescription)}
              dangerouslySetInnerHTML={{ __html: page.description }}
            />
          )}
          {content && content.map((component) => <ContentTypeComponent key={component.uuid} {...component} />)}
        </PageContent>
      </div>
    );
  }
}

const styles = (theme) => ({
  page: {
    margin: '40px 0',
  },
  articlePageContent: {
    maxWidth: 1166,
    margin: '0 auto',
  },
  componentWrapperInArticlePage: {
    maxWidth: 680,
    display: 'block',
    width: '100%',
  },
  articlePageDescription: {
    marginBottom: 40,
    fontSize: 18,
  },
  [theme.breakpoints.down('lg')]: {
    articlePageContent: {
      maxWidth: '75%',
      padding: 0,
      minWidth: 680,
    },
    componentWrapperInArticlePage: {
      maxWidth: '100%',
    },
  },
  [theme.breakpoints.down('ml')]: {
    articlePageContent: {
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
});

export default injectStyles(styles)(DynamicPage);
