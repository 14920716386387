import React, { useState, useEffect } from 'react';
import { Image } from 'api/interfaces';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { createUseStyles } from 'react-jss';
import styles from './ListTile.styles';

import { getImageWithResamplingQuery } from 'util/resampling';
import { isMobile, isTablet } from 'util/device';

import LazyTileImage from 'components/LazyTileImage';
import { calculateProgress, getDaysAgo, getAirDateWithYear } from 'lib/onDemand';
import * as cssVariable from 'cssVariables';
import * as analytics from 'analytics';
import { LABEL } from 'globalConst/const';
import { convertHMSToSeconds } from '../../../util/time';
import PlayControl from 'components/PlayControl';
import Podcast from 'api/models/Podcast';
import Duration from '../OnDemandTile/ClipProgress';

export interface Progress {
  completed?: boolean;
  durationLength?: number;
  contentLength?: number;
}
export interface ListTileProps {
  id?: string;
  author?: string;
  duration?: number;
  slug?: string;
  collectionId?: string;
  display?: string;
  error?: boolean;
  onDemandClipInPlayerTime: number;
  image?: Image;
  isActive?: boolean;
  imageUrl?: string;
  reference?: Podcast;
  collectionTitle?: string;
  isPlaying?: boolean;
  isInSearch?: boolean;
  contentTypeId?: string;
  title?: string;
  index?: number;
  onPlayPauseToggle: () => void;
  rowNum?: number;
  showNumbers?: boolean;
  isClipList?: boolean;
  publishDate?: string;
  onPlay?: () => void;
  isLoading?: boolean;
  progress?: Progress;
}

const useStyles = createUseStyles(styles, { name: 'ListTile' });
const ListTile = ({
  author,
  error,
  image,
  imageUrl,
  duration,
  isPlaying,
  onDemandClipInPlayerTime,
  isLoading,
  slug,
  title,
  collectionTitle,
  contentTypeId,
  isClipList = false,
  index,
  rowNum,
  showNumbers,
  reference,
  publishDate,
  progress,
  ...props
}: ListTileProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const redirectToPodcast = () => {
    if (isClipList) {
      if (!isPlaying) {
        analytics.trackPlayOnDemandClip({}, null, null, false, title, reference?.title);
      } else {
        analytics.trackStopOnDemand({}, null, null, false, title, reference?.title);
      }
      return props.onPlayPauseToggle();
    }
    analytics.trackGoToPodcast(collectionTitle, title);
    return history.push(redirectUrl);
  };
  const navTitleClick = () => {
    if (isClipList) {
      analytics.trackNavigateToPodcastEpisodePage(title, reference?.title, collectionTitle);
      return history.push(redirectUrl);
    } else {
      analytics.trackGoToPodcast(collectionTitle, title);
      return history.push(redirectUrl);
    }
  };
  const daysAgo = getDaysAgo(publishDate);
  const [redirectUrl, setRedirectUrl] = useState('');
  const daysLabels = [LABEL.TODAY, LABEL.YESTERDAY];
  const { contentLength: clipDuration = convertHMSToSeconds(duration) } = progress || {};

  useEffect(() => {
    if (isClipList && reference) {
      setRedirectUrl(`/podcasts/${reference.slug}/${slug}`);
    } else {
      setRedirectUrl(`/podcasts/${slug}`);
    }
  }, [isClipList, slug, reference]);

  const imgConfig = {
    url: image?.url || imageUrl,
    isSquare: true,
    customWidth: 70,
    customHeight: 70,
    retinaResize: false,
  };
  const playControlProps = {
    playToggle: props.onPlayPauseToggle,
    isPlaying: isPlaying && !isLoading,
    isLoading: isPlaying && isLoading,
    cachedProgress: 0,
    isClipList: true,
    contentTypeId: contentTypeId, //used as a id to store the station in recently searched list if isInSearch is true
    slug: slug,
    isTransparent: true,
    size: 35,
  };
  const clipProgress = onDemandClipInPlayerTime;

  return (
    <div data-testid={'listTile-wrapper'} className={classes.listTileWrapper}>
      <div data-testid={`listTile`} className={index % 2 === 0 ? classes.listTileEven : classes.listTileOdd}>
        <div className={classes.listTileImgWrapper}>
          <div onClick={redirectToPodcast}>
            <div
              className={classNames(classes.rowContainer, classes.thumbImage, {
                [classes.mobileRowContainer]: isMobile || isTablet(),
              })}
            >
              <div data-testid="listTile_play" className={classes.test}>
                {isClipList && (
                  <div className={classes.test2}>
                    <PlayControl {...playControlProps} podcastEpisode={title} podcastTitle={reference?.title} />
                  </div>
                )}
                <LazyTileImage
                  data-key="tile-image"
                  className={classes.listTileImage}
                  imageUrl={getImageWithResamplingQuery(imgConfig)}
                  isImg={true}
                ></LazyTileImage>
              </div>
            </div>
          </div>
        </div>
        {showNumbers && <div className={classes.enumeration}>{rowNum}</div>}
        <div className={classNames(classes.textWrapper, classes.info)}>
          {isClipList && (
            <div>
              <span data-testid={'listTile_author'} className={classes.authorText}>
                {daysLabels[daysAgo] || getAirDateWithYear(publishDate)}
              </span>
              {clipDuration === clipProgress && (
                <>
                  <span className={classes.authorText}> - </span>
                  <span className={classes.authorText}>
                    <Duration clipDuration={clipDuration} clipProgress={clipProgress}></Duration>
                  </span>
                </>
              )}
              {isPlaying && (
                <>
                  <span className={classes.authorText}> - </span>
                  <span className={classes.authorText}>
                    <Duration clipDuration={clipDuration} clipProgress={clipProgress}></Duration>
                  </span>
                </>
              )}
            </div>
          )}
          <h3 className={classes.title} data-testid={'listTile_title'}>
            <div className={classes.titleLink} onClick={navTitleClick}>
              {title}
            </div>
            {error && 'Podcast helaas niet gevonden'}
          </h3>
          {author && (
            <span data-testid={'listTile_author'} className={classes.authorText}>
              {author}
            </span>
          )}
        </div>
      </div>
      {isClipList && (
        <div
          style={{
            width: '100%',
            height: 2,
            backgroundColor: cssVariable.transparentLightmode005,
          }}
        >
          <div
            style={{
              width: `${calculateProgress(clipProgress, clipDuration)}%`,
              height: '100%',
              backgroundColor: 'rgb(70, 195, 29)',
              borderRadius: 1,
              transition: isPlaying && progress?.durationLength > 0 && 'width 1s linear',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ListTile;
