import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import DocumentMeta from 'react-document-meta';
import prepareMetaData from 'util/prepareMetaData';
import DefaultHeader from './DefaultHeader';
import PodcastDetailPageHeader from './PodcastDetailPageHeader';
import { PageSchema } from '../Schema';
import PodcastEpisodePageHeader from './PodcastEpisodePageHeader';

class PageHeader extends PureComponent {
  static defaultProps = {
    image: null,
    logo: null,
    isPodcastDetailPage: false,
    isDevicesPage: false,
    metadata: null,
    title: null,
    page: null,
    podcast: null,
    station: null,
    isStationPage: null,
    description: null,
    subtitle: null,
    pageLink: null,
    slug: null,
    contentTypeId: null,
  };

  static propTypes = {
    image: PropTypes.object,
    logo: PropTypes.object,
    isPodcastDetailPage: PropTypes.bool,
    metadata: PropTypes.object,
    title: PropTypes.string,
    page: PropTypes.object,
    podcast: PropTypes.object,
    station: PropTypes.object,
    isStationPage: PropTypes.bool,
    description: PropTypes.string,
    subtitle: PropTypes.string,
    pageLink: PropTypes.string,
    slug: PropTypes.string,
    contentTypeId: PropTypes.string,
    isPodcastEpisodePage: PropTypes.bool,
    episode: PropTypes.object,
  };

  getCanonicalUrl() {
    const { isPodcastEpisodePage } = this.props;
    let canonicalUrl = window.location.origin.concat(window.location.pathname);

    if (isPodcastEpisodePage) {
      const urlObject = new URL(canonicalUrl);
      const pathnameParts = urlObject.pathname.split('/');
      pathnameParts.pop();
      canonicalUrl = urlObject.origin + pathnameParts.join('/');
    }

    return canonicalUrl;
  }

  renderHeader = () => {
    const { metadata, podcast, isPodcastDetailPage, isPodcastEpisodePage, episode } = this.props;

    if (isPodcastEpisodePage)
      return (
        <Fragment>
          <DefaultHeader />
          <PodcastEpisodePageHeader episode={episode} {...episode} metadata={metadata} />
        </Fragment>
      );

    if (isPodcastDetailPage) {
      return (
        <Fragment>
          <DefaultHeader />
          <PodcastDetailPageHeader {...this.props} {...podcast} metadata={metadata} />
        </Fragment>
      );
    }

    return <DefaultHeader />;
  };

  render() {
    const { metadata, title, description, subtitle, page, pageLink, slug, image, logo, contentTypeId } = this.props;
    const canonicalUrl = this.getCanonicalUrl();
    const tags = (metadata || {}).tags;
    const pageType = (page || {}).pageType || contentTypeId;
    const type = pageType === 'station' && contentTypeId ? contentTypeId : pageType;

    return (
      <DocumentMeta {...prepareMetaData(metadata, title)} extend canonical={canonicalUrl}>
        <PageSchema
          type={type}
          name={title}
          id={pageLink || slug}
          image={image && image.url}
          logo={logo && logo.url}
          description={(metadata || {}).description || description || subtitle}
          keywords={tags && tags.join(',')}
        />
        {/* {this.renderHeader()} */}
      </DocumentMeta>
    );
  }
}

export default PageHeader;
