// import { ActivePodcastOrStation, PlaybackMode, PlayerEvents, IEvent, events } from '@4tn/core-audio-player-v2';
import * as analytics from 'analytics';
import invariant from 'invariant';
import { isChrome } from 'util/device';
import { isHuawei } from 'util/device';

import { EVENT_TYPE, TIME } from 'globalConst/const';

import AudioPlayer from './AudioPlayer';
import { logger } from 'util/index';
import getCapConfig from 'util/getCapConfig';

interface IPreviousClip {
  onDemandClipId: string;
  progress: number;
}

class AudioPlayerOnDemand extends AudioPlayer {
  trackProgress: boolean;
  previousClip: IPreviousClip | null;
  playbackTimeToSet: number;
  track: boolean;
  progressSentCallback: () => void;
  progressHeartbeat = {
    interval: null as ReturnType<typeof setInterval>,
    start: () => {
      clearInterval(this.progressHeartbeat.interval);
      this.progressHeartbeat.interval = setInterval(() => this.saveProgress(EVENT_TYPE.HEARTBEAT), TIME.HEARTBEAT);
    },
    stop: () => clearInterval(this.progressHeartbeat.interval),
  };

  constructor() {
    super();
    this.currentTime = 0;
    this.trackProgress = false;
    this.previousClip = null;
    this.playbackTimeToSet = null;

    // events.on(PlayerEvents.STREAM_METADATA_LOADED, this.onLoadedmetadata.bind(this));
  }

  /**
   * Initialises and load an AudioPlayer using the onDemandClip
   */
  async load(onDemandPlaybackTime: number): Promise<void> {
    try {
      const { imageUrl } = this.onDemandClip;

      if (this.onDemandClip.show) {
        const { name } = this.onDemandClip.show.station;
        this.setMediaSessionData(name, imageUrl);
      }

      if (this.track) {
        const { title } = this.onDemandClip;
        this.setMediaSessionData(title, imageUrl);
      }

      if (onDemandPlaybackTime) {
        this.playbackTimeToSet = onDemandPlaybackTime;
      }

      // Huawei QuickApp uses the native android player, if we start it here there are two players active
      if (!isHuawei) {
        const config = await getCapConfig(false);
        const onDemandPayload = {
          contentTypeId: this.onDemandClip.contentTypeId,
          id: this.onDemandClip.id,
          media: this.onDemandClip.media,
          slug: this.onDemandClip.slug,
        };
        // await this.player.play(onDemandPayload, config);
      }
    } catch (error) {
      // Don't propagate the error if the user aborts the load (ABORT_ERR)
      if (error.code !== DOMException.ABORT_ERR) {
        this.errorCallback(
          this.onDemandClip.audioUrl?.includes('http://') && isChrome()
            ? {
                name: 'MixedContent',
                message: 'http source is used',
              }
            : error,
          this.onDemandClip,
          this.playSessionId
        );
      }
    }
  }

  /**
   * Binds the given callback to the player metadata_loaded event
   */
  onLoadedmetadata(): void {
    if (this.playbackTimeToSet) {
      // this.player.seek(this.playbackTimeToSet);
      this.playbackTimeToSet = null;
    }
    // const { currentTime, duration } = this.player;
    // analytics.trackPlayOnDemandClip(this.onDemandClip, currentTime, duration);
  }

  /**
   * Binds the given callback to the audioElement's ended event
   */
  endedPlay(callback: () => void): void {
    // events.on(PlayerEvents.STREAM_ENDED, () => {
    //   callback();
    //   this.saveProgress(EVENT_TYPE.STOP);
    // });
  }

  /**
   * Resumes audio playback
   */
  async resume(): Promise<void> {
    // try {
    //   invariant(this.playSessionId && this.onDemandClip, 'PlaySessionId and onDemandClip should both be set.');
    //   // Huawei QuickApp uses the native android player, if we start it here there are two players active
    //   if (!isHuawei) {
    //     await this.player.resume();
    //   }
    //   const { currentTime, duration } = this.player;
    //   analytics.trackResumeOnDemand(this.onDemandClip, currentTime, duration);
    //   this.healthyPlayDetector.resume();
    // } catch (error) {
    //   this.errorCallback(error, this.playSessionId);
    //   logger.debug('Swallowed an error while calling resume()', error.code, error);
    // }
  }

  /**
   * Plays audio from given onDemandClip
   */
  async play(playSessionId: string, onDemandClip: unknown, onDemandPlaybackTime: number): Promise<void> {
    // const { currentTime } = this.player;
    // // Resume playback if it's the same sesseion
    // if (this.playSessionId === playSessionId) {
    //   if (currentTime !== onDemandPlaybackTime) {
    //     this.player.seek(onDemandPlaybackTime);
    //   }

    //   await this.resume();
    // } else {
    //   // Set and load content on new session
    //   this.playSessionId = playSessionId;
    //   this.previousClip = this.onDemandClip
    //     ? {
    //         onDemandClipId: this.onDemandClip.id,
    //         progress: this.player.currentTime || 0,
    //       }
    //     : null;
    //   this.onDemandClip = onDemandClip;

    //   analytics.trackStartOnDemandClip(this.onDemandClip);
    //   await this.load(onDemandPlaybackTime);

    //   this.healthyPlayDetector.startNew();
    // }

    this.saveProgress(EVENT_TYPE.START);
  }

  /**
   * Binds the given callback to the audioElement's durationChanged event
   */
  durationChanged(callback: (duration: number) => void): void {
    // events.on(PlayerEvents.DURATION_CHANGE, (event: IEvent) => {
    //   callback(event.duration || 0);
    // });
  }

  /**
   * Binds the given callback to the audioElement's emptied event
   */
  emptied(callback: () => void): void {
    // events.on(PlayerEvents.PLAYER_EMPTIED, () => {
    //   this.saveProgress(EVENT_TYPE.STOP);
    //   callback();
    // });
  }

  /**
   * Sets the callback triggered after a progress stop event
   */
  setProgressSentCallback(callback: () => void): void {
    this.progressSentCallback = callback;
  }

  /**
   * Moves the audio playback forward using the given skipTime
   */
  skipForward(skipTime: number): void {
    // const { currentTime, duration } = this.player;
    // if (!currentTime) {
    //   return;
    // }
    // this.saveProgress(EVENT_TYPE.STOP);
    // const seekValue = Math.min(currentTime + skipTime, duration);
    // this.player.seek(seekValue);
    // this.saveProgress(EVENT_TYPE.START);
    // if (this.onDemandClip) {
    //   analytics.trackSkipForwardOnDemand(skipTime, this.onDemandClip, currentTime, duration);
    // }
  }

  /**
   *  Moves the audio playback backward using the given skipTime
   */
  skipBackward(skipTime: number): void {
    // const { currentTime, duration } = this.player;
    // if (!currentTime) return;
    // this.saveProgress(EVENT_TYPE.STOP);
    // const seekValue = Math.max(currentTime - skipTime, 0);
    // this.player.seek(seekValue);
    // this.saveProgress(EVENT_TYPE.START);
    // if (this.onDemandClip) {
    //   analytics.trackSkipBackwardOnDemand(skipTime, this.onDemandClip, currentTime, duration);
    // }
  }

  /**
   *  Moves the audio playback to the given skipTime
   */
  skipTo(skipTime: number, trackAnalytics = true): void {
    // const { currentTime, duration } = this.player;
    // if (!currentTime) {
    //   return;
    // }
    // this.saveProgress(EVENT_TYPE.STOP);
    // this.player.seek(skipTime);
    // this.saveProgress(EVENT_TYPE.START);
    // if (this.onDemandClip && trackAnalytics) {
    //   analytics.trackSkipToOnDemand(skipTime, this.onDemandClip, currentTime, duration);
    // }
  }

  /**
   * Stops the audio playback
   */
  async stop(trackAnalytics = true): Promise<void> {
    // const { currentTime, duration } = this.player;
    // super.pause();
    // if (trackAnalytics) {
    //   // Stop every heartbeat that is active
    //   analytics.stopHeartBeat();
    //   if (this.onDemandClip) {
    //     analytics.trackStopOnDemand(this.onDemandClip, currentTime, duration);
    //   }
    // }
    // this.saveProgress(EVENT_TYPE.STOP);
  }

  /**
   * Saves progress of the audio clip to API via GraphQL
   */
  async saveProgress(type: string): Promise<void> {
    // if (!this.trackProgress || !this.onDemandClip) {
    //   return;
    // }
    // try {
    //   const paused = !this.player.isPlaying;
    //   if (type === EVENT_TYPE.STOP || (type === EVENT_TYPE.START && paused)) this.progressHeartbeat.stop();
    //   if (type === EVENT_TYPE.START && !paused) this.progressHeartbeat.start();
    //   // Make sure to fetch latest progress
    //   if (type === EVENT_TYPE.STOP) this.progressSentCallback();
    // } catch (error) {
    //   logger.error(`Something went wrong while saving ${type} progress for onDemandClip:`, error?.message);
    // }
  }

  /**
   * Returns the isPlaying value
   */
  get isPlaying(): boolean {
    return false;
    // return this.player.isPlaying && this.player.playbackMode === PlaybackMode.ON_DEMAND_CLIP;
  }
}

const audioPlayerOnDemand = new AudioPlayerOnDemand();

export default audioPlayerOnDemand;
