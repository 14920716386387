export const aspectRatios = {
  SQUARE: '1:1',
  FOUR_THREE: '4:3',
  PORTRAIT: '2:3',
  LANDSCAPE: '16:9',
};

const mockedVendor = { id: 0, scope: 'custom', name: '', purposes: [] };

export const STREAMING_APIS = [
  { url: 'facebook.com', ...mockedVendor },
  { url: 'instagram.com', ...mockedVendor },
  { url: 'spotify.com', ...mockedVendor },
  { url: 'twitter.com', ...mockedVendor },
  { url: 'youtu.be', ...mockedVendor },
  { url: 'youtube.com', ...mockedVendor },
];
