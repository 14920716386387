/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useFetchData } from 'components/hooks';
import CONTENT_TYPES from 'globalConst/contentTypes-const';

interface IPropsToInject {
  slug?: boolean;
  metadata?: boolean;
}

/**
 * HOC to inject correct station props based on tag reference
 *
 */
export default function (
  WrappedComponent: React.ElementType,
  propsToInject: IPropsToInject
): (props: any) => JSX.Element {
  const Component = (props: any) => {
    const { slug } = props;
    const { data, isLoading } = useFetchData({
      slug,
      contentTypeId: CONTENT_TYPES.BRAND,
    });

    // inject slug from tag's overview collection
    let refOverviewSlug = props.slug;
    if (propsToInject.slug && data) {
      if (!isLoading && Array.isArray(data?.refs)) {
        const collections = data.refs
          .filter(({ contentTypeId }) => ['overviewCollection'].includes(contentTypeId))
          .map(({ slug }: { slug: string }) => ({
            slug,
          }));

        refOverviewSlug = collections[0]?.slug;
      }
    }

    // inject metadata from tag's metadata object
    let tagMetadata = props.metadata || {};
    if (propsToInject.metadata && data) {
      tagMetadata = data.metadata;
    }
    return isLoading ? null : <WrappedComponent {...props} slug={refOverviewSlug} metadata={tagMetadata} />;
  };

  return Component;
}
