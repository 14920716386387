import React from 'react';
import { FOOTER_ID } from 'globalConst/const';
import styles from './Footer.styles';
import { createUseStyles } from 'react-jss';
import FooterNavigationCollection from 'components/FooterNavigationCollection';
import { NavigationCollection } from 'api/models';

const navigation = new NavigationCollection({
  title: 'Footer bottom nav 2',
  type: 'navigationCollection',
  slug: 'footer-bottom-nav-2',
  options: ['secondary'],
  getNavigations: {
    navigations: [
      {
        slug: 'bottom-navigation-2',
        title: 'Bottom navigation 2',
        uri: null,
        options: [],
        type: 'navigation',
        reference: {
          title: 'bottom collection navigation 2',
          getNavigations: {
            navigations: [
              {
                slug: 'gebruikersvoorwaarden',
                type: 'navigation',
                title: 'Gebruikersvoorwaarden',
                uri: 'https://privacy.talpanetwork.com/nl/voorwaarden',
                options: [],
                images: [],
              },
              {
                slug: 'privacyverklaring',
                type: 'navigation',
                title: 'Privacyverklaring',
                uri: 'https://privacy.talpanetwork.com/nl/privacy',
                options: [],
                images: [],
              },
              {
                slug: 'cookieverklaring',
                type: 'navigation',
                title: 'Cookieverklaring',
                uri: 'https://privacy.talpanetwork.com/nl/cookies',
                options: [],
                images: [],
              },
            ],
          },
        },
      },
    ],
  },
});

const useStyles = createUseStyles(styles, { name: 'Footer' });
const Footer = () => {
  const classes = useStyles();

  return (
    <footer id={FOOTER_ID}>
      <nav className={classes.footerContainer}>
        <FooterNavigationCollection {...navigation} />
      </nav>
    </footer>
  );
};

export default Footer;
