// import { createMetric as createMetricMutation } from './queries';
// import { queryRequest } from './client';
// import { logger } from 'util/index';

/**
 * Mutation to create metric
 * @param {string} metricData
 */
export const createMetric = async (metricData: string): Promise<void> => {
  // const inputMetric = {
  //   data: metricData,
  // };
  // return new Promise((resolve, reject) => {
  //   queryRequest({
  //     query: createMetricMutation,
  //     variables: { inputMetric },
  //     usePost: true,
  //   })
  //     .then((resp) => {
  //       return resolve(resp);
  //     })
  //     .catch((err) => {
  //       logger.error('Something went wrong while saving metric', err);
  //       reject();
  //     });
  // });
};
