import { createStore, applyMiddleware, compose } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { persistStore, persistCombineReducers, createTransform } from 'redux-persist';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';

import * as reducers from './reducers';
import middleware from './middleware';

const searchTransform = createTransform(
  (inboundState) => ({
    query: inboundState.query,
    recentItems: inboundState.recentItems,
  }),
  (outboundState) => ({ ...outboundState }),
  { whitelist: ['search'] }
);

const contentTransform = createTransform(
  (inboundState) => inboundState.progress,
  (outboundState) => ({ progress: outboundState }),
  { whitelist: ['content'] }
);

const playerTransform = createTransform(
  ({
    isPlaying,
    playSessionId,
    currentStation,
    currentOnDemandClip,
    volumeLevel,
    isMuted,
    playbackMode,
    onDemandPlaybackTime,
    onDemandPlaybackDuration,
    upcomingTracks,
  }) => ({
    isPlaying,
    playSessionId,
    currentStation,
    currentOnDemandClip,
    volumeLevel,
    isMuted,
    playbackMode,
    onDemandPlaybackTime,
    onDemandPlaybackDuration,
    upcomingTracks,
  }),
  (outboundState) => ({ ...outboundState, isPlaying: false }),
  { whitelist: ['player'] }
);

const persistConfig = {
  key: 'jukefm',
  storage,
  whitelist: [],
  // whitelist: ['stationHistory', 'favorites', 'search', 'content', 'player', 'popout'],
  transforms: [searchTransform, contentTransform, playerTransform],
  debug: true,
};

const rootReducer = persistCombineReducers(persistConfig, reducers);

let enhancers = null;
if (process.env.REACT_APP_REDUX_LOGGING_ENABLE === 'true') {
  enhancers = composeWithDevTools(
    responsiveStoreEnhancer,
    applyMiddleware(...middleware, createLogger({ collapsed: true }))
  );
} else {
  enhancers = compose(responsiveStoreEnhancer, applyMiddleware(...middleware));
}

export const store = createStore(rootReducer, enhancers);
export const persistor = persistStore(store);

export function getStore() {
  return store;
}
